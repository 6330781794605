import React from 'react';
import { NavLink } from 'react-router-dom';
import { intl } from '../../../../../i18n';
import messages from '../../../../displayMessages';
import { ItemResponse } from 'labxchange-client';

import { detailUrlForEntity } from '../../../../../library/utils';
import { WrappedMessage } from '../../../../../utils';
import { ItemIcon } from '../../../../../library/components';
import { getItemTypeMeta } from '../../../../../items/models';

export const LearnSkillCard = ({ asset }: { asset: ItemResponse }) => {

    const labXLogo = '/assets/images/career-explorer/labx-corner-logo.svg';
    const defaultAssetImage = '/assets/images/career-explorer/challenge-card.svg';

    return (
        <>
            {asset && (
                <div className='learn-skill-card'>
                    <div className='image-section'>
                        <div
                            className='image-section__asset-image'
                            data-testid='assetImage'
                            style={{backgroundImage: `url('${asset.metadata.imageUrl || defaultAssetImage}')`}}
                        />
                        <div className='image-section__asset-type'>
                            <ItemIcon itemType={asset.metadata.type} zoom='14'/>
                            <WrappedMessage
                                message={getItemTypeMeta(asset.metadata.type).name}
                            />
                        </div>
                    </div>

                    <div className='learn-skill-card__content'>
                        <h3>{asset.metadata.title}</h3>
                    </div>
                    <NavLink
                        to={detailUrlForEntity(asset.metadata)}
                        className='learn-skill-card__button'
                    >
                        <div className='learn-skill-card__cta-content'>
                            <WrappedMessage  message={messages.careerSkillCardCTAText}/>
                        </div>

                        <div>
                            <img data-testid='labXlogo' alt={intl.formatMessage(messages.altTextForImages)} src={labXLogo} className='logo'/>
                        </div>
                    </NavLink>
                </div>
            )}
        </>
    );
};
