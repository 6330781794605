import React from 'react';
import { Link } from 'react-router-dom';

import { ModuleSlab, SectionSlab } from 'labxchange-client';
import { CardsList } from 'home/components/TeachPage';
import { useLayoutSize } from 'utils';

import { convertSlabToFeaturedItems } from './utils';

interface InteractiveSlabProps {
  heading: string;
  slab: SectionSlab | ModuleSlab;
  moreLink?: string;
  moreText?: string;
  showFeedbackButton?: boolean;
  curriculumSlug?: string;
  module?: number;
}

export const InteractiveCardsSlab: React.FC<InteractiveSlabProps> = (props) => {
  const layoutSize = useLayoutSize();
  return (
    <div className='cards-container section'>
      <div className='more-header'>
        <h1 className='slab-title'>{props.heading}</h1>
        {props.moreLink && props.moreText && (
          <Link to={props.moreLink} className='more-link'>{props.moreText}</Link>
        )}
      </div>
      <div className='list-cards'>
          <CardsList
            cards={convertSlabToFeaturedItems(props.slab)}
            layoutSize={layoutSize}
            showFeedbackButton={props.showFeedbackButton}
            curriculumSlug={props.curriculumSlug}
            module={props.module}
          />
      </div>
    </div>
  );
};

export default InteractiveCardsSlab;
