import React, { useState } from 'react';
import { Icon } from '../../../../../elements';
import classNames from 'classnames';
import { CareerCheckBox } from 'career-explorer/components/CareerCheckBox';

interface SideBarParentProps {
    heading: string;
    description: string | React.ReactNode;
    children?: React.ReactNode;
    onClose: () => void;
    isOpen: boolean;
    className?: string;
    iconComponent?: React.ReactNode;
    isFullScreen?: boolean;
    completionLabel?: string;
    completionChecked?: boolean;
    toggleCompletionCheckbox?: (newValue: boolean) => void;
}

const SideBarParent: React.FC<SideBarParentProps> = ({
 heading,
 description,
 children,
 onClose,
 isOpen,
 iconComponent,
 isFullScreen,
 className,
 completionLabel,
 completionChecked=false,
 toggleCompletionCheckbox,
}) => {

    const toggleCheckbox = (newValue: boolean) => {
        if (toggleCompletionCheckbox){
            toggleCompletionCheckbox(newValue);
        }
    };

    return (
        <div className={classNames('career-sidebar', {'open': isOpen, 'closed': !isOpen, 'open-fullscreen': isFullScreen})}>
            <div className={`career-sidebar__header career-sidebar__header__${className}`}>
                <div className='career-sidebar__header__content'>
                    <div className='icon-heading'>
                        {iconComponent}
                        <h1>{heading}</h1>
                    </div>

                    <div onClick={onClose} className='close-button' role='button'>
                        <Icon name='clear' fill='#002F50' zoom='1em'/>
                    </div>
                </div>
            </div>
            <div className='career-sidebar__sidebar-main-content'>
                <div className='description'>
                    {description}
                </div>
                {children}
            </div>

            {completionLabel &&
                <div className='career-sidebar__completion'>
                    <div className='career-sidebar__completion-item'>
                        <CareerCheckBox checked={completionChecked} onClick={toggleCheckbox}>
                            {completionLabel}
                        </CareerCheckBox>
                    </div>
                </div>
            }
        </div>
    );
};

export default SideBarParent;
