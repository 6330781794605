/* istanbul ignore file */
import {
    getLoggedInStatus, getUsername,
    getUserEmail, getUserPermissions,
    getCurriculumGroup,
} from 'auth/selectors';
import { bind } from 'bind-decorator';
import { Badge, IconSymbol } from 'elements';
import { ItemsApi, AssetFeedbacksApi } from 'global/api';
import { PATHWAY_COVER_IMAGE_URL, ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { ItemType } from 'items/models';
import {
    APIPermissions,
    ChildItem,
    ItemMetadata,
    ItemResponse,
    ItemUserAttributes,
    ModerationRequestContentTypeEnum,
    PathwayDetail,
    AssetFeedbackResponse,
} from 'labxchange-client';
import messages, { getBackgroundKnowledgeLabel, getContentLicenseLabel } from 'library/displayMessages';
import {
    detailUrlForEntity,
    ItemInMaybePathway,
    readPreviousItemInBookFor,
    getFeedbackDetails,
} from 'library/utils';
import { locale } from 'i18n';
import * as React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
    BackToButton,
    Button,
    ExpandableText,
    Icon,
    ItemSection,
    ScreenReaderIntl,
    ShareModal,
    StandardPageLayout
} from 'ui/components';
import { setCurrentPathway, closeCongratulationModal } from 'library/actions';
import { LXThunkUnwrap } from 'global/types';

import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage, showLoginRequiredMessage, showSuccessMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { getLanguageNativeName } from 'i18n';
import { WrappedMessage } from 'utils';
import { createAssetViewedEventForItem } from '../Block/utils';
import { LibraryBreadcrumb, ResourceBreadcrumbButton } from '../Breadcrumb';
import { Card } from '../Card';
import { ContentSourceInfoBox } from '../ContentSourceInfoBox';
import { CreateTranslationModal } from '../CreateTranslationModal';
import { ItemActions } from '../ItemActions';
import { LanguageSelectorButton, SubjectTags } from '../ItemMetadataTable';
import { AuthorsList } from '../ItemPage/AuthorsList';
import { FundersList } from '../ItemPage/FundersList';
import { ItemStats } from '../ItemStats';
import { PathwayClone } from '../PathwayEditorWidget';
import { RecommendedContentWidget } from '../RecommendedContentWidget';
import { OwnerInfoBox } from '../OwnerInfoBox';
import { TranslationSelectorModal } from '../TranslationSelectorModal';
import { LanguageItem } from 'library/components/TranslationSelectorModal/TranslationSelectorModal';
import LearningObjectives from './LearningObjectives';
import { MessageDescriptor } from 'react-intl';
import { InvalidContent } from '../InvalidContent';
import { CongratulationsModal } from '../CongratulationsModal';
import Skeleton from 'react-loading-skeleton';
import { skeletonItemMetadata, skeletonPathwayDetail, skeletonUserAttributes } from 'skeletons';
import { Tag, FeedbackModal, FeedbackButton } from 'ui/components';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import { ResourcesListing } from '../ResourcesSection';
import { ReportButton } from '../../../moderation/components';


const dateFormatter = new Intl.DateTimeFormat(locale, {month: 'long', day: 'numeric', year: 'numeric'});

const wrapperClasses = 'col-md-12 col-lg-10 offset-lg-1';

interface PathwayMetadataInfoProps extends React.PropsWithChildren {
    iconName: IconSymbol;
    nameMessage: MessageDescriptor;
    valueMessage: MessageDescriptor;
    showSkeleton?: boolean;
}

class PathwayMetadataInfo extends React.PureComponent<PathwayMetadataInfoProps> {
    public render() {
        return <li className={this.props.showSkeleton ? 'skeleton' : ''}>{this.props.showSkeleton ? <div className='metadata-info-skeleton'>
            <div className='info-skeleton-icon'><Skeleton /></div>
            <div className='info-skeleton-description'><Skeleton count={3} /></div>
        </div> : <>
            <Icon name={this.props.iconName}/>
            <ScreenReaderIntl message={this.props.nameMessage} />
            <div>
            <strong>
            <small aria-hidden={true /* Duplicates <ScreenReaderIntl> above */}>
            <WrappedMessage message={this.props.valueMessage} />
            </small>
            </strong>
            {this.props.children}
            </div>
        </>}</li>;
    }
}

interface MatchProps {
    pathwayKey: string;
}

interface ActionProps {
    setCurrentPathway: typeof setCurrentPathway;
    closeCongratulationModal: typeof closeCongratulationModal;
}

interface ReduxStateProps {
    isLoggedIn: boolean;
    loggedInUsername: string;
    userPermissions?: APIPermissions;
    displayCongratulation: boolean;
    loggedInEmail?: string;
    curriculumGroup?: string;
}

interface Props extends RouteComponentProps<MatchProps>, LXThunkUnwrap<ActionProps>, ReduxStateProps {
}

interface State {
    infoBoxExpanded: boolean;
    loading: boolean;
    shareModalVisible: boolean;
    // The metadata and details of the current pathway:
    pathwayMetadata?: ItemMetadata;
    // If in a textbook and not the first item, this will be set to the
    // previous item in the textbook.
    previous?: ItemInMaybePathway;
    userAttributes?: ItemUserAttributes;
    pathwayDetail?: PathwayDetail;
    // If this pathway was cloned from another pathway, this is the metadata of
    // the "source" pathway, once we've loaded it from the API:
    pathwayClonedFrom?: ItemResponse;
    currentItem: number;
    similarItems?: ItemResponse[];
    moreItemsBySource?: ItemResponse[];

    createTranslationModalVisible: boolean;
    showLanguageSwitcherModal: boolean;
    translations: LanguageItem[];
    errStatus?: number;
    showFeedbackModal: boolean;
    feedbackData?: AssetFeedbackResponse;
    feedbackExists?: boolean;
    fetchingFeedback?: boolean;
}

/**
 * The PathwayPage is the main view of a user defined sequence of content
 * known as a pathway.
 *
 * It is comprised of metadata plus an ordered list of Card components.
 */
export class PathwayPageInternal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            currentItem: 0,
            infoBoxExpanded: false,
            loading: true,
            similarItems: [],
            moreItemsBySource: [],
            shareModalVisible: false,
            createTranslationModalVisible: false,
            showLanguageSwitcherModal: false,
            translations: [],
            errStatus: 404,
            pathwayMetadata: skeletonItemMetadata,
            showFeedbackModal: false,
            feedbackData: undefined,
            feedbackExists: false,
            fetchingFeedback: false,
        };
    }

    public async componentDidMount() {
        this.loadPathway();
        this.fetchFeedback();
    }

    private async fetchFeedback() {
        const { curriculumSlug, canUserGiveFeedback } = getFeedbackDetails(this.props.curriculumGroup);
        if (curriculumSlug && this.props.loggedInEmail && canUserGiveFeedback) {
            this.setState({ fetchingFeedback: true });
            try {
                const response = await AssetFeedbacksApi.feedbacksGetFeedback({
                    assetId: this.props.match.params.pathwayKey,
                    curriculumSlug,
                    userEmail: this.props.loggedInEmail,
                    isModulePageFeedback: false,
                });
                this.setState({
                    feedbackData: response,
                    feedbackExists: true,
                    fetchingFeedback: false
                });
            } catch (error) {
                this.setState({ feedbackExists: false, fetchingFeedback: false });
            }
        }
    }


    public async componentDidUpdate(prevProps: Props) {
        if (this.props.match.params.pathwayKey !== prevProps.match.params.pathwayKey ||
            (
                this.props.userPermissions?.explore.canUserGiveFeedback !==
                prevProps.userPermissions?.explore.canUserGiveFeedback
            )
        ) {
            this.setState({
                showLanguageSwitcherModal: false,
            });
            this.loadPathway();
            this.fetchFeedback();
        }


        // Trigger the pathway completion event when `displayCongratulation` becomes available.
        // `displayCongratulation` is only set to true once, right after the user clicks
        // "Complete" on the last pathway item.
        if (this.props.displayCongratulation && !prevProps.displayCongratulation) {
            analyticsInstance.track(
                EVENT_NAMES.PathwayCompletedSuccessfully, {
                    pathway_id: this.state.pathwayMetadata?.id,
                    url: window.location.toString(),
                }
            );
        }
    }

    private onClickTag(tag: string) {
        const target = `${ROUTES.Library.HOME}?t=Tag%3A${tag}`;
        this.props.history.push(target);
    }

    public render() {
        let pathway = this.state.pathwayMetadata;
        let pathwayDetail = this.state.pathwayDetail;
        let userAttributes = this.state.userAttributes;
        const { curriculumSlug, canUserGiveFeedback } = getFeedbackDetails(this.props.curriculumGroup);

        if (((pathway === undefined) ||
            (pathwayDetail === undefined) ||
            (userAttributes === undefined))) {
            if (!this.state.loading ) {
                return <InvalidContent statusCode={this.state.errStatus} />;
            } else {
                pathway = skeletonItemMetadata;
                pathwayDetail = skeletonPathwayDetail;
                userAttributes = skeletonUserAttributes;
            }
        }

        const recommendedItemsFooter = (
            <RecommendedContentWidget
                similarItems={this.state.similarItems? this.state.similarItems : []}
                moreItemsBySource={this.state.moreItemsBySource? this.state.moreItemsBySource : []}
            />
        );

        return (
            <>
                {this.props.displayCongratulation ?
                    <CongratulationsModal onClose={this.props.closeCongratulationModal} />
                : null}
                <StandardPageLayout
                    pageTitle={messages.pathwayPageTitle}
                    pageTitleValues={{name: pathway.title}}
                    headerFeature={this.renderHeader()}
                    headerBackgroundUrl={pathwayDetail.image ? PATHWAY_COVER_IMAGE_URL(pathwayDetail.image) : ''}
                    footerFeature={recommendedItemsFooter}
                    mainClassName='pathway-page-root'
                >
                    {this.state.pathwayMetadata?.id && !this.state.fetchingFeedback &&
                        canUserGiveFeedback && curriculumSlug &&
                        <FeedbackButton
                            variant={this.state.feedbackExists ? 'submitted' : 'pending'}
                            onClick={() => this.setState({showFeedbackModal: true})}
                        />
                    }
                    {this.state.showFeedbackModal && this.state.pathwayMetadata &&
                        curriculumSlug &&
                        <FeedbackModal
                            assetId={this.state.pathwayMetadata.id}
                            curriculumSlug={curriculumSlug}
                            onClose={() => this.setState({showFeedbackModal: false})}
                            onSubmit={() => this.setState(
                                {showFeedbackModal: false, feedbackExists: true})
                            }
                        />
                    }
                    <div className='hex-background-wrapper'>
                        <div className='hex-background left-hex-background' />
                        <div className='hex-background right-hex-background' />
                    </div>
                    {this.state.shareModalVisible &&
                        <ShareModal
                            itemTitle={pathway.title}
                            itemDescription={pathway.description}
                            itemImageUrl={pathway.imageUrl}
                            getItemUrl={() => detailUrlForEntity(pathway)}
                            onClose={() => { this.setState({shareModalVisible: false}); }}
                        />
                    }
                    {this.state.createTranslationModalVisible && this.state.pathwayMetadata &&
                        <CreateTranslationModal
                            metadata={this.state.pathwayMetadata}
                            onClose={() => { this.setState({createTranslationModalVisible: false}); }}
                            onConfirm={() => { this.setState({createTranslationModalVisible: false}); }}
                        />
                    }
                    <div className='pathway-wrapper row'>
                        <div className='info-box-wrapper col-12'>
                            <div className='info-box item-page-wrapper white-box' data-expanded={this.state.infoBoxExpanded}>
                                <div className='pathway-info'>
                                    <div className={`title-and-actions ${this.state.loading ? 'skeleton' : ''}`}>
                                        {this.state.loading ? <h1><Skeleton /></h1> : <>
                                        <div className='actions'>
                                            <ItemActions
                                                itemMetadata={pathway}
                                                loggedInUsername={this.props.loggedInUsername}
                                                userAttributes={userAttributes}
                                                userPermissions={this.props.userPermissions}
                                                onClone={this.onClone}
                                                onShare={() => { this.setState({shareModalVisible: true}); }}
                                                isUserLoggedIn={this.props.isLoggedIn}
                                                markAsIncomplete={this.markAsIncomplete}
                                                forceDisplayMarkAsIncomplete={this.completedPathwayItemsExists}
                                                onCreateTranslation={
                                                    () => this.setState({createTranslationModalVisible: true})
                                                }
                                            />
                                        </div>
                                        <h1 className='mb-0' dir='auto'>{pathway.title}</h1>
                                        </>}
                                    </div>
                                    <button
                                        className={classNames(
                                            'lx-btn',
                                            'show-more-button',
                                            {skeleton: this.state.loading}
                                        )}
                                        onClick={this.toggleInfoBoxExpand}
                                    >
                                        {this.state.loading ? <Skeleton count={2} />:
                                            <WrappedMessage message={uiMessages.uiShowMore}/>
                                        }
                                    </button>
                                    <div className='expandable-section'>
                                        {this.state.loading ? <div className='skeleton-description'>
                                            <Skeleton count={3} />
                                        </div>: <>
                                        <div className='stats m-0'><ItemStats {...pathway.stats}/></div>
                                        <div className='description' dir='auto'>
                                            <ExpandableText text={pathway.description} lines={3} html={true} />
                                        </div>
                                        <p className='pathway-dates'>
                                            <small>
                                                <WrappedMessage
                                                    message={messages.contentUploadedOnDate}
                                                    values={{date: dateFormatter.format(pathway.uploadedDate)}}
                                                />
                                            </small>
                                            {pathway.updatedAt &&
                                                <small>
                                                    <span>&nbsp;|&nbsp;</span>
                                                    <WrappedMessage
                                                        message={messages.contentUpdatedAtDate}
                                                        values={{date: dateFormatter.format(pathway.updatedAt)}}
                                                    />
                                                </small>
                                            }
                                        </p></>}
                                        <ul className='metadata list-unstyled mb-0'>
                                            <PathwayMetadataInfo
                                                iconName={'subject'}
                                                nameMessage={messages.metadataSubject}
                                                valueMessage={messages.metadataSubject}
                                                showSkeleton={this.state.loading}
                                            >
                                                <SubjectTags subjectTags={pathway?.subjectTags} />
                                            </PathwayMetadataInfo>
                                            <PathwayMetadataInfo
                                                iconName={'language'}
                                                nameMessage={messages.metadataLanguage}
                                                valueMessage={messages.metadataLanguage}
                                                showSkeleton={this.state.loading}
                                            >
                                                <small>
                                                    { pathway.language ? getLanguageNativeName(pathway.language) : '---' }
                                                    {this.state.translations.length > 1 ? <LanguageSelectorButton onClick={
                                                        () => this.setState({showLanguageSwitcherModal: true})
                                                    }/> : null}
                                                </small>
                                            </PathwayMetadataInfo>
                                            <PathwayMetadataInfo
                                                iconName={'background-knowledge'}
                                                nameMessage={messages.metadataBackgroundKnowledge}
                                                valueMessage={messages.metadataBackgroundKnowledge}
                                                showSkeleton={this.state.loading}
                                            >
                                                <small>
                                                    <WrappedMessage message={getBackgroundKnowledgeLabel(
                                                        pathway.backgroundKnowledge)} />
                                                </small>
                                            </PathwayMetadataInfo>
                                            <PathwayMetadataInfo
                                                iconName={'license'}
                                                nameMessage={messages.metadataLicense}
                                                valueMessage={messages.metadataLicense}
                                                showSkeleton={this.state.loading}
                                            >
                                                <small>
                                                    <WrappedMessage message={getContentLicenseLabel(pathway.license)} />
                                                </small>
                                            </PathwayMetadataInfo>
                                        </ul>
                                    </div>
                                    <button
                                        className='lx-btn show-less-button'
                                        onClick={this.toggleInfoBoxExpand}
                                    >
                                        <WrappedMessage message={uiMessages.uiShowLess}/>
                                    </button>
                                </div>
                                {
                                    pathway.source ?
                                        <ContentSourceInfoBox itemId={pathway.id || ''} {...pathway.source}/> :
                                        <OwnerInfoBox username={pathway.ownerIndividual} showSkeleton={this.state.loading} />
                                }
                            </div>
                        </div>

                        {this.renderStartButton()}

                        <div className={`child-items-cards-list-wrapper ${wrapperClasses}`}>
                            <ol className='child-items-cards-list'>
                                {pathwayDetail.items.map((child, index) => (
                                    <li
                                        key={index}
                                        className={`${this.state.currentItem === index ? 'current' : ''}
                                            ${child.item.userAttributes.completion === 1 ? 'complete' : ''}`}
                                    >
                                        {this.state.currentItem === index &&
                                            <span className='sr-only'>
                                                <WrappedMessage message={messages.pathwayItemCurrent} />
                                            </span>
                                        }
                                        {Boolean(child.item.userAttributes.completion === 1) &&
                                            <span className='sr-only'>
                                                <WrappedMessage message={messages.pathwayItemComplete} />
                                            </span>
                                        }
                                        <Card
                                            {...child.item}
                                            notes={child.notes}
                                            pathwayMetadata={this.state.pathwayMetadata}
                                            miniMobileMode={true}
                                            detailUrl={detailUrlForEntity(
                                                child.item.metadata,
                                                this.props.match.params.pathwayKey,
                                            )}
                                            onCardClick={this.onStart}
                                            showSkeleton={this.state.loading}
                                            isClipped={child.videoStartTime !== undefined && child.videoStopTime !== undefined}
                                            videoStartTime={child.videoStartTime}
                                            videoStopTime={child.videoStopTime}
                                        />
                                    </li>
                                ))}
                            </ol>
                        </div>

                        {this.renderStartButton()}

                        {this.state.pathwayMetadata?.resources  &&
                          <ResourcesListing
                            resources={this.state.pathwayMetadata.resources}
                            assetId={this.state.pathwayMetadata.id}
                            assetType={this.state.pathwayMetadata.type}
                            assetName={this.state.pathwayMetadata.title}
                            isPathwayView
                          />
                        }

                        {pathway.learningObjectives.length > 0 &&
                            <ItemSection
                                extraClasses={`metadata-container-wrapper learning-objectives ${wrapperClasses}`}
                                sectionName='learning-objectives-section'
                                title={
                                <div className={`learning-objectives-title`}>
                                    <WrappedMessage message={messages.metadataLearningObjectives}/>
                                </div>
                            }>
                                <LearningObjectives
                                    learningObjectives={pathway.learningObjectives}
                                />
                            </ItemSection>
                        }

                        <div className={`metadata-container-wrapper ${wrapperClasses}`}>
                            <FundersList funders={pathway.funders} />
                        </div>

                        <div className={`metadata-container-wrapper ${wrapperClasses}`}>
                            <AuthorsList authors={pathway.authors} />
                        </div>

                        { this.state.pathwayClonedFrom
                            ? <PathwayClone
                                metadata={this.state.pathwayClonedFrom.metadata}
                                userAttributes={this.state.pathwayClonedFrom.userAttributes}
                            />
                            : null
                        }

                        { pathway.freeFormTags &&
                            <div className={`metadata-container-wrapper ${wrapperClasses}`}>
                                {pathway.freeFormTags.map((tag, index) => (
                                    <Tag
                                        key={index}
                                        onClick={() => this.onClickTag(tag)}
                                        hoverType='color'
                                        disableIcon={true}
                                    >
                                        {tag}
                                    </Tag>
                                ))}
                            </div>
                        }

                        <div className={`metadata-container-wrapper ${wrapperClasses}`}>
                            {this.state.pathwayMetadata &&
                              <ReportButton
                                itemId={this.state.pathwayMetadata.id}
                                itemTitle={this.state.pathwayMetadata.title}
                                itemType={ModerationRequestContentTypeEnum.LibraryItemmetadata}
                                label={messages.actionReport}
                                asKebab={false}
                                onClose={() => null}
                                onOpen={() => null}
                              />
                            }
                        </div>

                        {this.languageSwitcher()}

                    </div>

                </StandardPageLayout>
            </>
        );
    }

    private async loadPathway() {
        const pathwayId = this.props.match.params.pathwayKey;
        let result: ItemResponse;
        let pathwayDetail: PathwayDetail;

        try {
            [result, pathwayDetail] = await Promise.all([
                ItemsApi.read({id: pathwayId}),
                ItemsApi.pathway({id: pathwayId}),
            ]);
            this.setState({
                loading: false,
                pathwayDetail,
                currentItem: this.getCurrentItem(pathwayDetail.items),
                pathwayMetadata: result.metadata,
                userAttributes: result.userAttributes,
            }, () => this.props.setCurrentPathway(pathwayId, pathwayDetail));
        } catch (err) {
            if (err.status) {
                this.setState({errStatus: err.status});
            } else {
                this.setState({errStatus: 500});
            }
            if (!this.props.isLoggedIn) {
                // Perhaps it didn't load because it's a private asset.
                showLoginRequiredMessage();
            }
            this.setState({
                loading: false,
            });
            return;
        }
        // Load the recommended items:
        ItemsApi.recommendedItems({
            id: pathwayId,
            similar: true,
            moreBySource: true,
        }).then((recommendedItems) => {
            if (this.props.match.params.pathwayKey === pathwayId) {// If pathway hasn't changed while loading:
                this.setState({
                    similarItems: recommendedItems.similarItems ? recommendedItems.similarItems : [],
                    moreItemsBySource: recommendedItems.moreBySource ? recommendedItems.moreBySource : [],
                });
            }
        });
        // If this pathway is cloned from another pathway, load the data about that pathway.
        // We do this at the same time as the recommended items are loading
        if (result.metadata.clonedFrom) {
            // Load the data about the original pathway
            ItemsApi.read({id: result.metadata.clonedFrom}).then((clonedFromData) => {
                // Now check to make sure the pathway hasn't changed in the meantime
                // (e.g. if the user clones the pathway, we might now be viewing the clone)
                if (this.state.pathwayMetadata &&
                    this.state.pathwayMetadata.clonedFrom === clonedFromData.metadata.id
                ) {
                    this.setState({pathwayClonedFrom: clonedFromData});
                }
            });
        } else {
            this.setState({pathwayClonedFrom: undefined});
        }

        // If the pathway is part of a textbook, load that textbook into state.
        // This will be used for displaying links to next/previous items in the
        // textbook.
        if (result.metadata.book) {
            const source = new URLSearchParams(this.props.location.search).get('source') || undefined;
            if (source !== undefined && source.startsWith(ROUTES.Library.BOOK_SLUG(''))) {
                this.setState({ previous: await readPreviousItemInBookFor(result.metadata.id) });
            }
        }

        try {
            const translations = await ItemsApi.languages({ id: pathwayId });
            this.setState({ translations });
        } catch (error) {
            showErrorMessage(<WrappedMessage message={messages.loadingTranslationsError}/>);
        }
    }

    @bind private async onClone(itemId: string) {
        try {
            const newPathway = await ItemsApi.pathwayClone({id: itemId});
            this.props.history.push(ROUTES.Library.PATHWAY_EDIT_SLUG(newPathway.metadata.id));
            showSuccessMessage(<WrappedMessage message={messages.pathwayCloneSucceeded}/>);
        } catch (err) {
            if(err.status === 403) {
                showLoginRequiredMessage();
            } else {
                showErrorMessage(<WrappedMessage message={messages.pathwayCloneFailed}/>, {
                    exception: err,
                    details: 'Unable to clone Pathway',
                });
            }

        }
    }

    private languageSwitcher() {
        return (
            this.state.pathwayMetadata && this.state.showLanguageSwitcherModal && <TranslationSelectorModal
                metadata={this.state.pathwayMetadata}
                onClose={() => this.setState({showLanguageSwitcherModal: false})}
                onConfirm={(translation) => {
                    this.props.history.push(detailUrlForEntity(
                        {id: translation.item, type: translation.type},
                    ));
                }}
            />
        );
    }

    @bind private toggleInfoBoxExpand(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.setState({infoBoxExpanded: !this.state.infoBoxExpanded});
    }

    private renderHeader() {
        const { location } = this.props;
        const queryParams = new URLSearchParams(location.search);
        const parentAsset = queryParams.get('assetId');
        const parentAssetType = queryParams.get('assetType');
        return (
            <div className={classNames('pathway-page-header-content container pt-md-5', {'extended':this.state.pathwayMetadata})}>
                <div>
                    {this.state.pathwayMetadata && this.state.pathwayMetadata.isPublic === false ?
                        <Badge iconName='lock' alternativeStyle={true} text={messages.privateIconText} />
                    : null}
                    {(parentAsset && parentAssetType) ?
                        <ResourceBreadcrumbButton
                            // @ts-ignore
                            itemType={parentAssetType}
                            itemId={parentAsset}
                        /> :
                        <LibraryBreadcrumb
                            book={this.state.pathwayMetadata && this.state.pathwayMetadata.book}
                            itemType={ItemType.Pathway}
                            itemTitle={this.state.pathwayMetadata?.title}
                        />
                    }
                </div>
                {this.state.userAttributes && this.state.userAttributes.canEditObject ?
                    <Button
                        btnStyle='ghost' label={messages.pathwayEdit} icon='pencil'
                        href={ROUTES.Library.PATHWAY_EDIT_SLUG(this.props.match.params.pathwayKey)}
                    /> : null}
            </div>
        );
    }

    private renderStartButton() {
        const pathway = this.state.pathwayMetadata;
        const pathwayDetail = this.state.pathwayDetail;
        const currentChild = pathwayDetail?.items[this.state.currentItem];
        let route = '';
        let overridePrevious;

        if (currentChild) {
            route = detailUrlForEntity(currentChild.item.metadata, pathway?.id);
            const previous = this.state.previous;
            if (previous) {
                overridePrevious = {
                    source: detailUrlForEntity(previous.item, previous.pathwayId),
                    message: messages.previousInBook,
                };
            }
        }

        return (
            <div className={`start-button-wrapper ${wrapperClasses}`}>
                {this.state.loading ? <Skeleton /> : <>
                <div className='backtobutton-wrapper d-flex float-left mr-0'>
                    {this.state.previous ? <BackToButton overrideSource={overridePrevious} /> : null }
                    <BackToButton />
                </div>
                <NavLink
                    to={route}
                    className='primary-button float-right mr-0'
                    onClick={this.onStart}
                >
                    <Icon name='triangle-right'/>
                    {this.completedPathwayItemsExists ?
                    <WrappedMessage message={messages.pathwayResume}/>
                    :
                    <WrappedMessage message={messages.pathwayStart}/>}
                </NavLink></>}
            </div>
        );
    }

    @bind private onStart(event?: React.MouseEvent) {
        if (this.state.pathwayMetadata) {
            createAssetViewedEventForItem(this.state.pathwayMetadata.id, false);
        }
    }


    private get completedPathwayItemsExists() {
        const completedItems = this.state.pathwayDetail?.items.filter(({ item }) => {
            if (item.userAttributes && item.userAttributes.completion) {
                return item.userAttributes.completion > 0;
            }
            return false;
        }) || [];
        return completedItems.length > 0;
    }

    @bind private markAsIncomplete() {
        if (this.state.pathwayMetadata?.id) {
            ItemsApi.markIncomplete({ id: this.state.pathwayMetadata.id})
                .then(() => this.loadPathway())
                .catch(error => showErrorMessage('Error while changing completion state.', {exception: error}));
        }
    }

    /**
     * Returns the index of the current item in the list of pathway child items.
     */
    private getCurrentItem(childItems: ChildItem[]): number {
        // Here, the current item is defined as the first uncompleted item, or
        // the last item if all completed.

        for (let i = 0; i < childItems.length; i++) {
            if (!childItems[i].item.userAttributes.completion) {
                // We found an uncompleted item; return.
                return i;
            }
        }

        // all were completed; return last index
        return childItems.length - 1;
    }
}


export const PathwayPage = connect<ReduxStateProps, ActionProps, {}, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
        loggedInUsername: getUsername(state),
        userPermissions: getUserPermissions(state),
        displayCongratulation: state.libraryState.displayCongratulation,
        loggedInEmail: getUserEmail(state),
        curriculumGroup: getCurriculumGroup(state),
    }), {
        setCurrentPathway,
        closeCongratulationModal,
    }
)(PathwayPageInternal);
