// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubSkill
 */
export interface SubSkill {
    /**
     * 
     * @type {number}
     * @memberof SubSkill
     */
    readonly id?: number;
    /**
     * The title of the subskill.
     * @type {string}
     * @memberof SubSkill
     */
    title: string;
    /**
     * Description of the subskill.
     * @type {string}
     * @memberof SubSkill
     */
    description: string;
    /**
     * 
     * @type {Array<ItemResponse>}
     * @memberof SubSkill
     */
    itemIds?: Array<ItemResponse>;
}

export function SubSkillFromJSON(json: any): SubSkill {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'description': json['description'],
        'itemIds': !exists(json, 'item_ids') ? undefined : (json['item_ids'] as Array<any>).map(ItemResponseFromJSON),
    };
}

export function SubSkillToJSON(value?: SubSkill): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'description': value.description,
        'item_ids': value.itemIds === undefined ? undefined : (value.itemIds as Array<any>).map(ItemResponseToJSON),
    };
}


