// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AssetFeedbackRequest
 */
export interface AssetFeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackRequest
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackRequest
     */
    curriculum: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackRequest
     */
    userEmail: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackRequest
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackRequest
     */
    grade?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AssetFeedbackRequest
     */
    modules?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetFeedbackRequest
     */
    tags?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AssetFeedbackRequest
     */
    isContentUseful: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackRequest
     */
    tellUsMoreFeedback?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssetFeedbackRequest
     */
    isModulePageFeedback: boolean;
}

export function AssetFeedbackRequestFromJSON(json: any): AssetFeedbackRequest {
    return {
        'asset': json['asset'],
        'curriculum': json['curriculum'],
        'userEmail': json['user_email'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'grade': !exists(json, 'grade') ? undefined : json['grade'],
        'modules': !exists(json, 'modules') ? undefined : json['modules'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'isContentUseful': json['is_content_useful'],
        'tellUsMoreFeedback': !exists(json, 'tell_us_more_feedback') ? undefined : json['tell_us_more_feedback'],
        'isModulePageFeedback': json['is_module_page_feedback'],
    };
}

export function AssetFeedbackRequestToJSON(value?: AssetFeedbackRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'asset': value.asset,
        'curriculum': value.curriculum,
        'user_email': value.userEmail,
        'subject': value.subject,
        'grade': value.grade,
        'modules': value.modules,
        'tags': value.tags,
        'is_content_useful': value.isContentUseful,
        'tell_us_more_feedback': value.tellUsMoreFeedback,
        'is_module_page_feedback': value.isModulePageFeedback,
    };
}


