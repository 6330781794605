import { InputText } from '@edx/paragon';
import { Button } from '@labxchange/ui-components';
import { bind } from 'bind-decorator';
import messages from 'library/displayMessages';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import {showErrorMessage, Tag} from 'ui/components';
import { WrappedMessage } from 'utils';
import { intl } from 'i18n';

interface TagEditorProps {
    tags: ReadonlyArray<string>;
    onChange?: (tags: string[]) => void;
    showErrors: boolean;
    required: boolean;
    placeholder: MessageDescriptor;
    label?: MessageDescriptor;
    helpText?: MessageDescriptor;
    hideToast?: boolean;
    disableNewTags?: boolean;
    maxTags?: number;
    maxTagsError?: MessageDescriptor;
    maxTagsErrorValues?: {[key: string]: any};
}

interface TagEditorState {
    tagToastCss: React.CSSProperties;
    tagString: string;
    screenReaderMessage: string;
}

export class TagsEditor extends React.PureComponent<TagEditorProps, TagEditorState> {

    constructor(props: TagEditorProps) {
        super(props);
        this.state = {
            tagString: '',
            tagToastCss: {display: 'none'},
            screenReaderMessage: '',
        };
    }

    public render() {
        const button = (
            <Button className='add-button btn py-0' onClick={this.addTag}>
                <WrappedMessage message={messages.pathwayTagsAddButton}/>
            </Button>
        );

        return (
            <div className='tags-editor-wrapper'>
                <div className='tags-label-container'>
                    {!this.props.disableNewTags && this.props.label &&
                        <span>
                            <WrappedMessage message={this.props.label}/>
                        </span>
                    }
                </div>
                <div className='tags-editor-responsive-container'>
                    <div className='existing-tags-block'>
                        {!this.props.disableNewTags && <>
                            <InputText
                                name='tag_editor_text_input'
                                inline={true}
                                aria-label='tags editor'
                                placeholder={intl.formatMessage(this.props.placeholder)}
                                value={this.state.tagString}
                                className={`tags-input-box add-input form-control ${this.props.showErrors ? 'lx-input-error' : ''}`}
                                onChange={this.trackTagString}
                                onKeyPress={this.handleKeyPress}
                                inputGroupAppend={button}
                            />
                            {this.props.helpText && <p className='help-text'><WrappedMessage message={this.props.helpText}/></p>}
                        </>}
                        {this.props.tags.map((tag, index) => (
                            <Tag key={index} onClick={this.removeTag.bind(this, index)} ariaDescribedBy='remove-tag-button-description'>
                                {tag}
                            </Tag>
                        ))}

                        <div id='remove-tag-button-description' className='sr-only'>
                            <WrappedMessage message={messages.removeTagSRText}/>
                        </div>
                    </div>
                </div>
                {!this.props.hideToast &&
                    <div className='add-toast' style={this.state.tagToastCss}>
                        &nbsp;<WrappedMessage message={messages.pathwayTagsSuccessToast}/>
                    </div>
                }

                <span className='sr-only' aria-live='polite'>
                    {this.state.screenReaderMessage}
                </span>
            </div>
        );
    }

    @bind private trackTagString(value: string) {
        this.setState({ tagString: value });
    }

    @bind private handleKeyPress(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.addTag();
        }
    }

    @bind private addTag() {
        // parse by comma and deduplicate tags
        const newTagArray = this.state.tagString
            .split(',')
            .map( (x) => x.trim() )
            .filter( (x) => x );
        let tagArray = newTagArray.concat(...this.props.tags);
        tagArray = [...new Set(tagArray)];

        if (this.props.maxTagsError && this.props.maxTags && tagArray.length > this.props.maxTags) {
            showErrorMessage(
                <WrappedMessage message={this.props.maxTagsError} values={this.props.maxTagsErrorValues}/>,
            );
            return;
        }

        if (this.props.onChange) {
            this.props.onChange(tagArray);
        }

        this.setState({
            screenReaderMessage: intl.formatMessage(
                newTagArray.length > 1 ? messages.tagsAddedSRMessage : messages.singleTagAddedSRMessage,
                {tagString: newTagArray.join(', ')}
            )
        });

        if (tagArray) {
            // display toast
            this.setState({ tagString: '' });
            this.setState({ tagToastCss: {display: 'inline'} });
            setTimeout(() => { this.setState({ tagToastCss: {display: 'none'} }); }, (2 * 1000));
        }
    }

    private removeTag(index: number) {
        const tagArray = this.props.tags.slice(); // Create a copy, never mutate the original
        const deletedTags = tagArray.splice(index, 1);
        this.setState(
            {
                screenReaderMessage: intl.formatMessage(
                    messages.tagDeletedSRMessage, {tagString: deletedTags[0]}
                )
            }
        );

        if (this.props.onChange) {
            this.props.onChange(tagArray);
        }
    }
}
