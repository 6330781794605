import React from 'react';

import { Icon } from 'elements';
import { CareerQuote } from 'labxchange-client';
import { sanitizeConfigOptions, sanitizeUnsafeHTML } from '@labxchange/util-sanitization';


interface QuoteProps {
  quote: CareerQuote;
  imageClass?: string;
  iconColor: string;
}

const Quote: React.FC<QuoteProps> = ({ quote, imageClass, iconColor }) => {
  if (!quote) {
    return null;
  }

  const placeholderImage = '/assets/images/career-explorer/author-profile-placeholder.svg';

  return (
    <div className='column info'>
      <div className='info-header'>
        <div className='info-icon'>
          <Icon name='quote-v2' fill={iconColor} />
        </div>
        <div className='info-text'>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizeUnsafeHTML(quote.quote, sanitizeConfigOptions.UnsafeHTMLSimple)
            }}
          />”
        </div>
      </div>
      <div className='info-details'>
        <div className='info-avatar'>
          <img className={imageClass} src={quote.authorImage || placeholderImage } alt='avatar' />
        </div>
        <div className='info-person'>
          <strong className='person-name'>{quote.authorName}</strong>
          <span className='person-role'> - {quote.authorDesignation}</span>
        </div>
      </div>
    </div>
  );
};

export default Quote;
