import * as React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  ariaLabel?: string;
}

export function Button({ className, onClick, ariaLabel, children }: ButtonProps) {
  return (
    <button className={className} onClick={onClick} aria-label={ariaLabel}>
      {children}
    </button>
  )
}
