import { defineMessages } from 'react-intl';

const messages = defineMessages({
    allContentTypes: {
        id:'allContentTypes',
        defaultMessage: 'All content types',
        description: 'Default title when no filter is selected.',
    },
    anonymousUserWarning: {
        id:'anonymousUserWarning',
        defaultMessage: 'To save your results, please {signIn} or {signUp} before answering the question.',
        description: 'Warning shown when displaying a question to users who are not logged in',
    },
    anonymousUserSignInText: {
        id:'anonymousUserSignInText',
        defaultMessage: 'log in',
        description: 'Text used for the "sign in" link in the anonymousUserWarning',
    },
    anonymousUserSignUpText: {
        id:'anonymousUserSignUpText',
        defaultMessage: 'create a free account',
        description: 'Text used for the "sign up" link in the anonymousUserWarning',
    },
    languageSelectorModalTitle: {
        id:'languageSelectorModalTitle',
        defaultMessage: 'Select language',
        description: 'Title for the language selector modal',
    },
    languageSelectorModalConfirm: {
        id:'languageSelectorModalConfirm',
        defaultMessage: 'Set language',
        description: 'Submit button for the language selector modal',
    },
    languageChangeLabel: {
        id:'languageChangeLabel',
        defaultMessage: 'Change',
        description: 'Change label for the language selector button',
    },
    languageFilterPlaceholder: {
        id:'languageFilterPlaceholder',
        defaultMessage: 'Filter languages',
        description: 'Placeholder message for filtering languages',
    },
    loadingTranslationsError: {
        id:'loadingTranslationsError',
        defaultMessage: 'Something went wrong while loading available translations. Please try again later.',
        description: 'Error message when backend failed to return available translations.',
    },
    actionCreateTranslation: {
        id:'actionCreateTranslation',
        defaultMessage: 'Clone for translation',
        description: 'Label for clone a translation action button',
    },
    actionCopyInternalID: {
        id:'actionCopyInternalID',
        defaultMessage: 'Copy Internal ID',
        description: 'Label for copy to clipboard action button',
    },
    actionCreateTranslationTitle: {
        id:'actionCreateTranslationTitle',
        defaultMessage: 'Do you want to clone this asset for translation?',
        description: 'Title for create a translation action modal title',
    },
    actionCreateTranslationConfirm: {
        id:'actionCreateTranslationConfirm',
        defaultMessage: 'Clone',
        description: 'Label for create a translation action confirm button',
    },
    actionCreateTranslationCancel: {
        id:'actionCreateTranslationCancel',
        defaultMessage: 'Cancel',
        description: 'Label for create a translation action cancel button',
    },
    actionCreateTranslationError: {
        id:'actionCreateTranslationError',
        defaultMessage: 'Error cloning. {error_message}',
        description: 'Message for create a translation action error',
    },
    actionFavorite: {
        id:'actionFavorite',
        defaultMessage: 'Favorite',
        description: 'Button label that will update this item as a favorite',
    },
    actionAddFavorite: {
        id:'actionAddFavorite',
        defaultMessage: 'Add to favorites',
        description: 'Button help text that will save this item as a favorite',
    },
    actionRemoveFavorite: {
        id:'actionRemoveFavorite',
        defaultMessage: 'Remove from favorites',
        description: 'Button help text that will remove this item from favorites',
    },
    actionUnfavorite: {
        id:'actionUnfavorite',
        defaultMessage: 'Unfavorite',
        description: 'Button help text that will unfavorite an item',
    },
    actionShare: {
        id:'actionShare',
        defaultMessage: 'Share',
        description: 'Button label that will share this item on social media',
    },
    actionDelete: {
        id:'actionDelete',
        defaultMessage: 'Delete',
        description: 'Button label that will permanently delete this item',
    },
    deleteItemConfirm: {
        id:'deleteItemConfirm',
        defaultMessage: 'Are you sure you want to delete {title}?',
        description: 'Confirmation text for the item delete modal.',
    },
    deleteItemWarn: {
        id:'deleteItemWarn',
        defaultMessage: 'This will remove it from your content, pathways, and classes. This cannot be undone.',
        description: 'Warning for the item delete modal.',
    },
    deleteItemBlocked: {
        id:'deleteItemBlocked',
        defaultMessage: 'Public assets cannot be deleted. Please make this asset private before deleting it.',
        description: 'Message shown if a user tries to delete a public asset directly from the kebab menu without first making it private.',
    },
    deleteItemErrorMessage: {
        id:'deleteItemErrorMessage',
        defaultMessage: 'This asset could not be deleted. You might not have permission to do so.',
        description: 'Message shown if user cannot delete the asset.',
    },
    deleteItemSuccess: {
        id:'deleteItemSuccess',
        defaultMessage: 'This item was successfully deleted',
        description: 'Message shown if user successfully deleted the asset.',
    },
    actionReport: {
        id:'actionReport',
        defaultMessage: 'Report',
        description: 'Button label that will report this item',
    },
    blockDocumentShowLabel: {
        id:'blockDocumentShowLabel',
        defaultMessage: 'View document',
        description: 'Label for button to show document.',
    },
    blockNarrativeKeyPoints: {
        id:'blockNarrativeKeyPoints',
        defaultMessage: 'Key Points',
        description: 'Heading for the "Key Points" section of Narrative assets.',
    },
    blockAssignmentStartLabel: {
        id:'blockAssignmentStartLabel',
        defaultMessage: 'Start question set',
        description: 'Label for button to start assignment.',
    },
    blockAssignmentTotalScoreLabel: {
        id:'blockAssignmentTotalScoreLabel',
        defaultMessage: 'Total points',
        description: 'Label for total score of an assignment.',
    },
    blockAssignmentStartNextLabel: {
        id:'blockAssignmentStartNextLabel',
        defaultMessage: 'Start next',
        description: 'Label for button to move to next asset of assignment.',
    },
    blockTeachingGuideStartLabel: {
        id:'blockTeachingGuideStartLabel',
        defaultMessage: 'Start teaching guide',
        description: 'Label for button to start teaching guide.',
    },
    blockCaseStudyStartLabel: {
        id:'blockCaseStudyStartLabel',
        defaultMessage: 'Start case study',
        description: 'Label for button to start case study.',
    },
    blockCaseStudyAmgenScholarsProgramTitle: {
        id:'blockCaseStudyAmgenScholarsProgramTitle',
        defaultMessage: 'AMGEN Scholars Program',
        description: 'Title AMGEN Scholars program.',
    },
    blockCaseStudyAmgenScholarsProgramDescription: {
        id:'blockCaseStudyAmgenScholarsProgramDescription',
        defaultMessage: `
            Each summer hundreds of undergraduates step into some of the world’s premier research universities and
             institutions to participate in the Amgen Scholars Program.
        `,
        description: 'Description for AMGEN Scholars program.',
    },
    blockCaseStudyAmgenScholarsProgramLearnMoreLabel: {
        id:'blockCaseStudyAmgenScholarsProgramLearnMoreLabel',
        defaultMessage: 'Learn about the program and how to apply.',
        description: 'Label for link to further details about the AMGEN Scholars program.',
    },
    blockCaseStudyCopyFullReferenceLabel: {
        id:'blockCaseStudyCopyFullReferenceLabel',
        defaultMessage: 'Copy full reference',
        description: 'Label for button to copy case study full reference.',
    },
    blockCaseStudyCopyInTextReferenceLabel: {
        id:'blockCaseStudyCopyInTextReferenceLabel',
        defaultMessage: 'Copy in-text reference',
        description: 'Label for button to copy case study in-text reference.',
    },
    blockInteractiveStartLabel: {
        id:'blockInteractiveStartLabel',
        defaultMessage: 'Start interactive',
        description: 'Label for button to start interactive.',
    },
    blockOverviewBackLabel: {
        id:'blockOverviewBackLabel',
        defaultMessage: 'Back to overview',
        description: 'Label for button to go back to asset overview.',
    },
    blockOverviewTotalPointsLabel: {
        id:'blockOverviewTotalPointsLabel',
        defaultMessage: 'Total points',
        description: 'Label for total points on the block overview.',
    },
    blockOverviewPossiblePointsLabel: {
        id:'blockOverviewPossiblePointsLabel',
        defaultMessage: '{earned}/{possible} possible points',
        description: 'Label for possible points on the assignment sidebar.',
    },
    blockSimulationStartLabel: {
        id:'blockSimulationStartLabel',
        defaultMessage: 'Start simulation',
        description: 'Label for button to start simulation.',
    },
    blockAnnotatedVideoStartLabel: {
        id:'blockAnnotatedVideoStartLabel',
        defaultMessage: 'Start annotated video',
        description: 'Label for button to start annotated video.',
    },
    congratulationModalHeader: {
        id:'congratulationModalHeader',
        defaultMessage: 'Great work',
        description: 'Pathway completion congratulations modal header.',
    },
    congratulationModalText: {
        id:'congratulationModalText',
        defaultMessage: 'You completed this pathway.',
        description: 'Pathway completion congratulations modal text.',
    },
    congratulationModalButton: {
        id:'congratulationModalButton',
        defaultMessage: 'Done',
        description: 'Pathway completion congratulations modal action button.',
    },
    contentFiltersToggle: {
        id:'contentFiltersToggle',
        defaultMessage: 'Show/Hide more content filter tabs',
        description: 'Label for the button that toggles the content filter tags in the library.',
    },
    contentSourceEnrollAction: {
        id:'contentSourceEnrollAction',
        defaultMessage: 'Enroll now on edX',
        description: `Button encouraging users to enroll in the course that the content originally came from.`,
    },
    contentSourceDescriptionPart: {
        id:'contentSourceDescriptionPart',
        defaultMessage: 'and',
        description: `Used to concatenate multiple organizations names i.e org1, org2 and org3`,
    },
    contentSourceInformation: {
        id:'contentSourceInformation',
        defaultMessage: 'Learn more',
        description: `Button encouraging users to get more information about the source of the content.`,
    },
    contentSourceInfoButtonText: {
        id:'contentSourceInfoButtonText',
        defaultMessage: 'Website',
        description: `Button encouraging users to visit source website`,
    },
    contentSourceSimple: {
        id:'contentSourceSimple',
        defaultMessage: 'This learning resource was made by {organizationNames}.',
        description: `
            Describes where the content came from.
            organizationNames is a provider like "LabXchange", "edX", or "Harvard"
        `,
    },
    contentSourceVerbose: {
        id:'contentSourceVerbose',
        defaultMessage: 'This learning resource is from the {sourceName}. It was made by {organizationNames}.',
        description: 'Lists the source of the asset content. A sourceName can be a course, book, or webpage name and organizationNames is an organization like "Harvard".',
    },
    contentUploadedOnDate: {
        id:'contentUploadedOnDate',
        defaultMessage: 'Uploaded {date}',
        description: 'Describes the date when the content was uploaded.',
    },
    contentPublishedOnDate: {
        id:'contentPublishedOnDate',
        defaultMessage: 'Published {date}',
        description: 'Describes the date when the content was published.',
    },
    contentUpdatedAtDate: {
        id:'contentUpdatedAtDate',
        defaultMessage: 'Updated {date}',
        description: 'Describes the date when the content was last updated.',
    },
    backgroundKnowledgeLabel_: {
        id:'backgroundKnowledgeLabel_',
        defaultMessage: '---',
        description: 'Label when "Background Knowledge" is not set.',
    },
    backgroundKnowledgeLabel_none: {
        id:'backgroundKnowledgeLabel_none',
        defaultMessage: 'None',
        description: 'Label when "Background Knowledge" required is "None".',
    },
    backgroundKnowledgeLabel_some: {
        id:'backgroundKnowledgeLabel_some',
        defaultMessage: 'Some',
        description: 'Label when "Background Knowledge" required is "Some".',
    },
    backgroundKnowledgeLabel_extensive: {
        id:'backgroundKnowledgeLabel_extensive',
        defaultMessage: 'Extensive',
        description: 'Label when "Background Knowledge" required is "Extensive".',
    },
    discussionAction: {
        id:'discussionAction',
        defaultMessage: '{value} the conversation',
        description: 'Labels the button to continue an asset conversation',
    },
    errorLoadingDiscussion: {
        id:'errorLoadingDiscussion',
        defaultMessage: 'Error while loading discussion.',
        description: 'Error message when an error occur while loading discussion.',
    },
    discussionInvitation: {
        id:'discussionInvitation',
        defaultMessage: 'Have something to discuss?',
        description: 'Invites user to use the discussion forums',
    },
    errorChangeFavorite: {
        id:'errorChangeFavorite',
        defaultMessage: 'Failed to update favorite',
        description: 'Error message shown if adding/removing a favorite item fails',
    },
    filterClear: {
        id:'filterClear',
        defaultMessage: 'Clear all',
        description: 'Label for the button that clears search keywords and selected facets.',
    },
    filterToggle: {
        id:'filterToggle',
        defaultMessage: 'Filter',
        description: 'Label for the button that toggles the filter sidebar in the library.',
    },
    fundersListTitle: {
        id:'fundersListTitle',
        defaultMessage: 'Created with support from',
        description: 'Title for the list of funders.',
    },
    libraryCardAuthors: {
        id:'libraryCardAuthors',
        defaultMessage: '{firstAuthor} +{count, number}',
        description: `
            Displayed on library card to identify the authors
            when the item was authored by multiple authors.
        `,
    },
    libraryCardMenuButtonTitle: {
        id:'libraryCardMenuButtonTitle',
        defaultMessage: 'Open menu',
        description: 'Title of the vertical kebab menu button on library cards',
    },
    libraryCardMenuPreviewTitle: {
        id:'libraryCardMenuPreviewTitle',
        defaultMessage: 'Preview',
        description: 'Title of the preview button on library cards',
    },
    libraryCardMenuViewTitle: {
        id:'libraryCardMenuViewTitle',
        defaultMessage: 'View',
        description: 'Title of the view button on library cards',
    },
    libraryCardMenuClipVideoTitle: {
        id:'libraryCardMenuClipVideoTitle',
        defaultMessage: 'Clip video',
        description: 'Title of the clip video button on library cards',
    },
    libraryCardMenuLaunchTitle: {
        id:'libraryCardMenuLaunchTitle',
        defaultMessage: 'Launch',
        description: 'Title of the launch button on library cards',
    },
    libraryCardMenuFavoriteTitle: {
        id:'libraryCardMenuFavoriteTitle',
        defaultMessage: 'Favorite',
        description: 'Title of the favorite button on library cards',
    },
    libraryCardMenuNotesTitle: {
        id:'libraryCardMenuNotesTitle',
        defaultMessage: 'Notes',
        description: 'Title of the notes button on library cards',
    },
    libraryCardMenuEditTitle: {
        id:'libraryCardMenuEditTitle',
        defaultMessage: 'Edit',
        description: 'Title of the edit button on library cards',
    },
    libraryCardMenuDeleteTitle: {
        id:'libraryCardMenuDeleteTitle',
        defaultMessage: 'Remove',
        description: 'Title of the delete button on library cards',
    },
    libraryCatalog: {
        id:'libraryCatalog',
        defaultMessage: 'Library catalog',
        description: 'Describes everything that is in the library',
    },
    libraryContentError: {
        id:'libraryContentError',
        defaultMessage: 'Error: Unable to display this component.',
        description: 'Text when a component fails to load correctly.',
    },
    libraryCreateContentButtonLabel: {
        id:'libraryCreateContentButtonLabel',
        defaultMessage: 'Add new content',
        description: 'Label for button for creating new assets (shown on organization and educator dashboards).',
    },
    libraryViewButtonLabel: {
        id:'libraryViewButtonLabel',
        defaultMessage: 'Library',
        description: 'Redirects user to library page.',
    },
    markAsIncomplete: {
        id: 'markAsIncomplete',
        defaultMessage: 'Mark as incomplete',
        description: 'Label for button for marking asset as incomplete.',
    },
    pathwayAddNewCard: {
        id:'pathwayAddNewCard',
        defaultMessage: 'Add content',
        description: 'Text to click on for adding a first new card to a pathway',
    },
    pathwayAddNextCard: {
        id:'pathwayAddNextCard',
        defaultMessage: 'Add content',
        description: 'Text to click on for adding an additional new card to a pathway',
    },
    pathwayAddNewLearningObjective: {
        id:'pathwayAddNewLearningObjective',
        defaultMessage: 'Add a learning objective',
        description: 'Text to click on to add a new learning objective',
    },
    pathwayCardBadUrlError: {
        id:'pathwayCardBadUrlError',
        defaultMessage: 'Content URL is not valid.',
        description: 'Error message for when URL of item to be added to the pathway is not valid',
    },
    pathwayCardItemFetchError: {
        id:'pathwayCardItemFetchError',
        defaultMessage: 'Unable to get data for that item.',
        description: 'Error message for when trying to get item details',
    },
    pathwayCardMoveDown: {
        id:'pathwayCardMoveDown',
        defaultMessage: 'Move down',
        description: 'Label for the button that moves an item in the pathway to the next position.',
    },
    pathwayCardMoveUp: {
        id:'pathwayCardMoveUp',
        defaultMessage: 'Move up',
        description: 'Label for the button that moves an item in the pathway to the previous position.',
    },
    pathwayCardSave: {
        id:'pathwayCardSave',
        defaultMessage: 'Save',
        description: 'Button to save item url to a pathway',
    },
    pathwayCardUrl: {
        id:'pathwayCardUrl',
        defaultMessage: 'Content URL',
        description: 'Label for adding content to a pathway',
    },
    pathwayCloneSectionTitle: {
        id:'pathwayCloneSectionTitle',
        defaultMessage: 'Cloned pathway',
        description: 'Title for cloned section on pathway',
    },
    pathwayCloneAction: {
        id:'pathwayCloneAction',
        defaultMessage: 'Clone',
        description: 'Label for clone action button for a pathway',
    },
    copyAndEdit: {
        id:'copyAndEdit',
        defaultMessage: 'Copy and edit',
        description: 'Label for clone action button for a pathway',
    },
    pathwayCloneFailed: {
        id:'pathwayCloneFailed',
        defaultMessage: 'Failed to clone pathway. Try again later.',
        description: 'An error message when backend failed to clone a pathway',
    },
    pathwayCloneSucceeded: {
        id:'pathwayCloneSucceeded',
        defaultMessage: 'This pathway has been cloned. You may edit the new cloned pathway now.',
        description: 'Shown when the user clones a pathway.',
    },
    pathwayChildNotesLabel: {
        id:'pathwayChildNotesLabel',
        defaultMessage: 'Notes',
        description: 'Label for the pathway child item notes textarea',
    },
    pathwayChildDelete: {
        id:'pathwayChildDelete',
        defaultMessage: 'Delete item',
        description: 'Label for the button that deletes an item from a pathway',
    },
    pathwayChildOnlyVideoPortion: {
        id: 'pathwayChildOnlyVideoPortion',
        defaultMessage: 'Play only a portion of this video',
        description: 'Label on a checkbox to select to choose start and end timestamps of video to play.',
    },
    pathwayChildVideoStartTime: {
        id:'pathwayChildVideoStartTime',
        defaultMessage: 'Start position',
        description: 'Label for form field where users can customize the time that the video starts playing.',
    },
    pathwayChildVideoStopTime: {
        id:'pathwayChildVideoStopTime',
        defaultMessage: 'Stop position',
        description: 'Label for form field where users can customize the time that the video stops playing.',
    },
    pathwayChildVideoEndTime: {
        id:'pathwayChildVideoEndTime',
        defaultMessage: 'End position',
        description: 'Label for form field where users can customize the time that the video ends playing.',
    },
    pathwayChildUpdate: {
        id:'pathwayChildUpdate',
        defaultMessage: 'Update item',
        description: 'Label for the button that updates an item in a pathway',
    },
    pathwayContentError: {
        id:'pathwayContentError',
        defaultMessage: 'Error: Unable to display this pathway.',
        description: 'Text when a pathway fails to load correctly.',
    },
    pathwayCreateHeading: {
        id:'pathwayCreateHeading',
        defaultMessage: 'Create Pathway',
        description: 'Heading for the pathway create page.',
    },
    pathwayBackgroundKnowledge: {
        id:'pathwayBackgroundKnowledge',
        defaultMessage: 'Background Knowledge*',
        description: 'Label for the background knowledge select box.',
    },
    pathwayDelete: {
        id:'pathwayDelete',
        defaultMessage: 'Delete pathway',
        description: 'Label for the pathway delete button.',
    },
    pathwayDeleteConfirm: {
        id:'pathwayDeleteConfirm',
        defaultMessage: 'Are you sure you want to delete {title}?',
        description: 'Confirmation text for the pathway delete modal.',
    },
    pathwayDeleteBody: {
        id:'pathwayDeleteBody',
        defaultMessage: 'This action is final and cannot be undone.',
        description: 'Text for pathway delete modal body',
    },
    pathwayDescription: {
        id:'pathwayDescription',
        defaultMessage: 'Description',
        description: 'Label for the pathway description textarea.',
    },
    pathwayDescriptionPlaceholder: {
        id:'pathwayDescriptionPlaceholder',
        defaultMessage: 'Enter pathway description',
        description: 'Helper text for the pathway description textarea.',
    },
    pathwayEdit: {
        id:'pathwayEdit',
        defaultMessage: 'Edit pathway',
        description: 'Label for the edit pathway link',
    },
    pathwayEditPageTitle: {
        id:'pathwayEditPageTitle',
        defaultMessage: '{name} - Edit Pathway',
        description: 'HTML title for the pathway edit page.',
    },
    pathwayImageEdit: {
        id:'pathwayImageEdit',
        defaultMessage: 'Change cover image',
        description: 'Label for change cover image button.',
    },
    pathwayImageUrl: {
        id:'pathwayImageUrl',
        defaultMessage: 'Image url*',
        description: 'Label for adding image to a pathway',
    },
    pathwayImageModalTitle: {
        id:'pathwayImageModalTitle',
        defaultMessage: 'Choose a cover image',
        description: 'Modal title for updating Pathway cover',
    },
    pathwayImageModalSubmitButton: {
        id:'pathwayImageModalSubmitButton',
        defaultMessage: 'Set cover image',
        description: 'Modal submit button for updating Pathway cover',
    },
    pathwayItemPageTitle: {
        id:'pathwayItemPageTitle',
        defaultMessage: '{index}. {name}',
        description: 'HTML title for the pathway item page.',
    },
    pathwayLicense: {
        id:'pathwayLicense',
        defaultMessage: 'License*',
        description: 'Label for the license select box.',
    },
    pathwayPageTitle: {
        id:'pathwayPageTitle',
        defaultMessage: '{name} - Pathway',
        description: 'HTML title for the pathway page.',
    },
    pathwayPreview: {
        id:'pathwayPreview',
        defaultMessage: 'Preview',
        description: 'Label for the preview pathway edit button.',
    },
    pathwayPreviewNext: {
        id:'pathwayPreviewNext',
        defaultMessage: 'Preview next',
        description: 'Label for the preview next item in pathway',
    },
    pathwayPublishPrivately: {
        id:'pathwayPublishPrivately',
        defaultMessage: 'Save Pathway',
        description: 'Label for the pathway publish privately button.',
    },
    pathwayPublishPublicly: {
        id:'pathwayPublishPublicly',
        defaultMessage: 'Publish',
        description: 'Label for the pathway publish publicly button.',
    },
    pathwaySave: {
        id:'pathwaySave',
        defaultMessage: 'Save draft',
        description: 'Label for the save pathway edit as draft button.',
    },
    pathwaySaveContinue: {
        id:'pathwaySaveContinue',
        defaultMessage: 'Save edit',
        description: 'Label for the publish/save and continue editing pathway button.',
    },
    pathwaySavedTitle: {
        id:'pathwaySavedTitle',
        defaultMessage: 'Success',
        description: 'Title message upon successfully saving and continuing to edit a pathway.',
    },
    pathwaySaved: {
        id:'pathwaySaved',
        defaultMessage: 'Your changes to the pathway have been saved.',
        description: 'Message upon successfully saving and continuing to edit a pathway.',
    },
    pathwaySection: {
        id:'pathwaySection',
        defaultMessage: 'Pathways',
        description: 'Describes the section of the app dedicated to pathways',
    },
    pathwayStart: {
        id:'pathwayStart',
        defaultMessage: 'Start pathway',
        description: 'Label for the button that starts a pathway',
    },
    pathwayResume: {
        id:'pathwayResume',
        defaultMessage: 'Resume pathway',
        description: 'Label for the button that resumes the already started pathway',
    },
    pathwayStartNext: {
        id:'pathwayStartNext',
        defaultMessage: 'Next',
        description: 'Label for the button that starts the next item in a pathway',
    },
    pathwayBack: {
        id:'pathwayBack',
        defaultMessage: 'Back',
        description: 'Label for the button that starts the previous item in a pathway',
    },
    pathwayComplete: {
        id:'pathwayComplete',
        defaultMessage: 'Complete',
        description: 'Label for the button that completes a pathway or book.',
    },
    previousInBook: {
        id:'previousInBook',
        defaultMessage: 'Previous',
        description: 'Label for the button that goes to the previous asset in a book from a pathway.',
    },
    pathwayTags: {
        id:'pathwayTags',
        defaultMessage: 'Subject*',
        description: 'Label for the subject tags select box.',
    },
    pathwayTagsAddButton: {
        id:'pathwayTagsAddButton',
        defaultMessage: 'Add',
        description: 'Label for the button to add tags to a pathway',
    },
    pathwayTagsAddLabel: {
        id:'pathwayTagsAddLabel',
        defaultMessage: 'Add tags to help users to find your content. Separate the tags with commas.',
        description: 'Label for the input field to add tags to a pathway',
    },
    pathwayTagsEditor: {
        id:'pathwayTagsEditor',
        defaultMessage: 'Tags',
        description: 'Title for the tag editor box.',
    },
    pathwayTagsPlaceholder: {
        id:'pathwayTagsPlaceholder',
        defaultMessage: 'Enter tags',
        description: 'Helper text for the tag editor box',
    },
    pathwayTagsSuccessToast: {
        id:'pathwayTagsSuccessToast',
        defaultMessage: 'Added!',
        description: 'Toast for when tags are added to a pathway',
    },
    singleTagAddedSRMessage: {
        id:'singleTagAddedSRMessage',
        defaultMessage: '{tagString} tag added.',
        description: 'Screen reader message when tags are added to a pathway',
    },
    tagsAddedSRMessage: {
        id:'tagsAddedSRMessage',
        defaultMessage: '{tagString} tags added.',
        description: 'Screen reader message when tags are added to a pathway',
    },
    tagDeletedSRMessage: {
        id:'tagDeletedSRMessage',
        defaultMessage: '{tagString} tag deleted.',
        description: 'Screen reader message when a tag is removed from a pathway',
    },
    removeTagSRText: {
        id: 'removeTagSRText',
        defaultMessage: 'To remove this tag click this button',
        description: 'Screen reader message for remove tag button'
    },
    pathwayTitle: {
        id:'pathwayTitle',
        defaultMessage: 'Pathway name*',
        description: 'Label for the pathway name input box.',
    },
    pathwayTitlePlaceholder: {
        id:'pathwayTitlePlaceholder',
        defaultMessage: 'Enter pathway name',
        description: 'Helper text for the pathway name input box.',
    },
    pathwayUpdateHeading: {
        id:'pathwayUpdateHeading',
        defaultMessage: 'Update Pathway',
        description: 'Heading for the pathway edit page.',
    },
    pathwayPublicTitle: {
        id:'pathwayPublicTitle',
        defaultMessage: 'Public pathway',
        description: 'Title for public pathway section.',
    },
    pathwayPublicText: {
        id:'pathwayPublicText',
        defaultMessage: 'Public pathways are added to the LabXchange library, and can be accessed by all users.',
        description: 'Text description for public pathway section.',
    },
    pathwayErrorMakePublicWithPrivateAssetsModalTitle: {
        id:'pathwayErrorMakePublicWithPrivateAssetsModalTitle',
        defaultMessage: 'This pathway contains private assets',
        description: 'Pathway error modal title when updating to public with private assets.',
    },
    pathwayErrorMakePublicWithPrivateAssetsModalBody: {
        id:'pathwayErrorMakePublicWithPrivateAssetsModalBody',
        defaultMessage: 'Please remove them or publish them publicly, and try again.',
        description: 'Pathway error modal body when updating to public with private assets.',
    },
    pathwayDefaultLearningObjectiveDescription: {
        id:'pathwayDefaultLearningObjectiveDescription',
        defaultMessage: 'Enter a learning objective',
        description: 'placeholder text for learning objective description field',
    },
    queryClear: {
        id:'queryClear',
        defaultMessage: 'Clear',
        description: 'Label for the button that clears search query.',
    },
    referencesHeading: {
        id:'referencesHeading',
        defaultMessage: 'References',
        description: 'Label for the References.',
    },
    videoTranscriptsHeader: {
        id: 'videoTranscriptsHeader',
        defaultMessage: 'Transcript',
        description: 'Video transcript section header text',
    },
    videoTranscriptsTranslate: {
        id: 'videoTranscriptsTranslate',
        defaultMessage: 'Translate',
        description: 'Translate button for video transcripts',
    },
    itemEdit: {
        id:'itemEdit',
        defaultMessage: 'Customize',
        description: 'Label for the customize asset link',
    },
    itemPreview: {
        id:'itemPreview',
        defaultMessage: 'Preview',
        description: 'Label for the preview asset link',
    },
    itemDelete: {
        id:'itemDelete',
        defaultMessage: 'Are you sure you want to remove “{title}”?',
        description: 'Label for the delete asset modal title.',
    },
    itemCancel: {
        id:'itemCancel',
        defaultMessage: 'Cancel',
        description: 'Label for the cancel delete modal button',
    },
    itemPageTitle: {
        id:'itemPageTitle',
        defaultMessage: '{name}',
        description: 'HTML title for viewing an item/asset.',
    },
    itemStatsClonesCount: {
        id:'itemStatsClonesCount',
        defaultMessage: '{count, number} {count, plural, one {Clone} other {Clones}}',
        description: `
            The number of times this asset has been Cloned. Only term "Clone" and "Clones" need to be translated.
             Translator can ignore and leave remaining brackets or variables (count, number etc) as is.
             The syntax indicates that the phrase in {} after "one" will be used when the count is 1 and the phrase in {} after "other" will be used when the count is more than 1.
             An example of translated string can be "{count, number} {count, plural, one {Translation of Clone} other {Translation of Clones}}".
        `,
    },
    itemStatsFavouriteCount: {
        id:'itemStatsFavouriteCount',
        defaultMessage: '{count, number} {count, plural, one {Favorite} other {Favorites}}',
        description: `
            The number of times this asset has been favorited or liked by users. Only term "Favorite" and "Favorites" need to be translated.
             Translator can ignore and leave remaining brackets or variables (count, number etc) as is.
             The syntax indicates that the phrase in {} after "one" will be used when the count is 1 and the phrase in {} after "other" will be used when the count is more than 1.
             An example of translated string can be "{count, number} {count, plural, one {Translation of Favorite} other {Translation of Favorites}}".
        `,
    },
    itemStatsRemixCount: {
        id:'itemStatsRemixCount',
        defaultMessage: '{count, number} {count, plural, one {Remix} other {Remixes}}',
        description: `
            The number of times this asset has been remixed. Remix is a numerical value that indicates how many times an asset was used in pathways.
             Only term "Remix" and "Remixes" need to be translated. Translator can ignore and leave remaining brackets or variables (count, number etc) as is.
             The syntax indicates that the phrase in {} after "one" will be used when the count is 1 and the phrase in {} after "other" will be used when the count is more than 1.
             An example of translated string can be "{count, number} {count, plural, one {Translation of Remix} other {Translation of Remixes}}".
        `,
    },
    itemStatsViewCount: {
        id:'itemStatsViewCount',
        defaultMessage: '{count, number} {count, plural, one {View} other {Views}}',
        description: `
            The number of times this asset has been Viewed. Only term "View" and "Views" need to be translated.
             Translator can ignore and leave remaining brackets or variables (count, number etc) as is.
             The syntax indicates that the phrase in {} after "one" will be used when the count is 1 and the phrase in {} after "other" will be used when the count is more than 1.
             An example of translated string can be "{count, number} {count, plural, one {Translation of View} other {Translation of Views}}".
        `,
    },
    finishedVideoHeader: {
        id:'finishedVideoHeader',
        defaultMessage: 'You have finished this video snippet',
        description: 'Label for the header on the finished video modal',
    },
    finishedVideoDescription: {
        id:'finishedVideoDescription',
        defaultMessage: 'Would you like to carry on watching the video or start the next section in the pathway?',
        description: 'Label for the description on the finished video modal',
    },
    finishedVideoContinueButton: {
        id:'finishedVideoContinueButton',
        defaultMessage: 'Continue watching',
        description: 'Label for the continue-watching button on the finished video modal',
    },
    finishedVideoNextButton: {
        id:'finishedVideoNextButton',
        defaultMessage: 'Start next',
        description: 'Label for the start-next button on the finished video modal',
    },

    /* eslint-disable no-useless-computed-key */
    ['License name: LX1']: {
        id: 'licenseNameLX1',
        defaultMessage: 'LabXchange Standard License',
        description: 'LabXchange Standard License',
    },
    ['License name: PD']: {
        id: 'licenseNamePD',
        defaultMessage: 'Public Domain',
        description: 'Public Domain',
    },
    ['License URL: LX1']: {
        id: 'licenseURLLX1',
        defaultMessage: 'https://about.labxchange.org/tos',
        description: 'URL to the page with information about the LabXchange Standard License in this language.',
    },
    ['License name: CC BY 4.0']: {
        id: 'licenseNameCCBY40',
        defaultMessage: 'Attribution (CC BY 4.0)',
        description: 'Shortened name of the Creative Commons Attribution 4.0 International license.',
    },
    ['License URL: CC BY 4.0']: {
        id: 'licenseURLCCBY40',
        defaultMessage: 'https://creativecommons.org/licenses/by/4.0/',
        description: 'URL to the page with information about the Creative Commons Attribution 4.0 International license in this language.',
    },
    ['License name: CC BY-NC 4.0']: {
        id: 'licenseNameCCBYNC40',
        defaultMessage: 'Attribution-NonCommercial (CC BY-NC 4.0)',
        description: 'Shortened name of the Creative Commons Attribution-NonCommercial 4.0 International license.',
    },
    ['License URL: CC BY-NC 4.0']: {
        id: 'licenseURLCCBYNC40',
        defaultMessage: 'https://creativecommons.org/licenses/by-nc/4.0/',
        description: 'URL to the page with information about the Creative Commons Attribution-NonCommercial 4.0 International license in this language.',
    },
    ['License name: CC BY 3.0']: {
        id: 'licenseNameCCBY30',
        defaultMessage: 'Attribution (CC BY 3.0)',
        description: 'Shortened name of the Creative Commons Attribution 3.0 Unported license.',
    },
    ['License URL: CC BY 3.0']: {
        id: 'licenseURLCCBY30',
        defaultMessage: 'https://creativecommons.org/licenses/by/3.0/',
        description: 'URL to the page with information about the Creative Commons Attribution 3.0 Unported license in this language.',
    },
    ['License name: CC BY-NC 3.0']: {
        id: 'licenseNameCCBYNC30',
        defaultMessage: 'Attribution-NonCommercial (CC BY-NC 3.0)',
        description: 'Shortened name of the Creative Commons Attribution-NonCommercial 3.0 Unported license.',
    },
    ['License URL: CC BY-NC 3.0']: {
        id: 'licenseURLCCBYNC30',
        defaultMessage: 'https://creativecommons.org/licenses/by-nc/3.0/',
        description: 'URL to the page with information about the Creative Commons Attribution-NonCommercial 3.0 Unported license in this language.',
    },
    ['License name: CC BY 2.5']: {
        id: 'licenseNameCCBY25',
        defaultMessage: 'Attribution (CC BY 2.5)',
        description: 'Shortened name of the Creative Commons Attribution 2.5 Generic license.',
    },
    ['License URL: CC BY 2.5']: {
        id: 'licenseURLCCBY25',
        defaultMessage: 'https://creativecommons.org/licenses/by/2.5/',
        description: 'URL to the page with information about the Creative Commons Attribution 2.5 Generic license in this language.',
    },
    ['License name: CC BY-NC 2.5']: {
        id: 'licenseNameCCBYNC25',
        defaultMessage: 'Attribution-NonCommercial (CC BY-NC 2.5)',
        description: 'Shortened name of the Creative Commons Attribution-NonCommercial 2.5 Generic license.',
    },
    ['License URL: CC BY-NC 2.5']: {
        id: 'licenseURLCCBYNC25',
        defaultMessage: 'https://creativecommons.org/licenses/by-nc/2.5/',
        description: 'URL to the page with information about the Creative Commons Attribution-NonCommercial 2.5 Generic license in this language.',
    },
    ['License name: CC BY 2.0']: {
        id: 'licenseNameCCBY2',
        defaultMessage: 'Attribution (CC BY 2.0)',
        description: 'Shortened name of the Creative Commons Attribution 2.0 Generic license.',
    },
    ['License URL: CC BY 2.0']: {
        id: 'licenseURLCCBY2',
        defaultMessage: 'https://creativecommons.org/licenses/by/2.0/',
        description: 'URL to the page with information about the Creative Commons Attribution 2.0 Generic license in this language.',
    },
    ['License name: CC BY-NC 2.0']: {
        id: 'licenseNameCCBYNC2',
        defaultMessage: 'Attribution-NonCommercial (CC BY-NC 2.0)',
        description: 'Shortened name of the Creative Commons Attribution-NonCommercial 2.0 Generic license.',
    },
    ['License URL: CC BY-NC 2.0']: {
        id: 'licenseURLCCBYNC2',
        defaultMessage: 'https://creativecommons.org/licenses/by-nc/2.0/',
        description: 'URL to the page with information about the Creative Commons Attribution-NonCommercial 2.0 Generic license in this language.',
    },
    ['License name: CC BY 1.0']: {
        id: 'licenseNameCCBY1',
        defaultMessage: 'Attribution (CC BY 1.0)',
        description: 'Shortened name of the Creative Commons Attribution 1.0 Generic license.',
    },
    ['License URL: CC BY 1.0']: {
        id: 'licenseURLCCBY1',
        defaultMessage: 'https://creativecommons.org/licenses/by/1.0/',
        description: 'URL to the page with information about the Creative Commons Attribution 1.0 Generic license in this language.',
    },
    ['License name: CC BY-NC 1.0']: {
        id: 'licenseNameCCBYNC1',
        defaultMessage: 'Attribution-NonCommercial (CC BY-NC 1.0)',
        description: 'Shortened name of the Creative Commons Attribution-NonCommercial 1.0 Generic license.',
    },
    ['License URL: CC BY-NC 1.0']: {
        id: 'licenseURLCCBYNC10',
        defaultMessage: 'https://creativecommons.org/licenses/by-nc/1.0/',
        description: 'URL to the page with information about the Creative Commons Attribution-NonCommercial 1.0 Generic license in this language.',
    },
    /* eslint-enable no-useless-computed-key */
    licenseNameUnknown: {
        id:'licenseNameUnknown',
        defaultMessage: 'Unknown license',
        description: 'Displayed when the license is unknown due to an error',
    },
    metadataAuthor: {
        id:'metadataAuthor',
        defaultMessage: 'Author',
        description: 'Label for the author who authored the content',
    },
    metadataBackgroundKnowledge: {
        id:'metadataBackgroundKnowledge',
        defaultMessage: 'Background Knowledge',
        description: 'Label for Background Knowledge',
    },
    metadataContentType: {
        id:'metadataContentType',
        defaultMessage: 'Content Type',
        description: 'Label for Content Type',
    },
    metadataCurator: {
        id:'metadataCurator',
        defaultMessage: 'Curator',
        description: 'Label for the curator of this item',
    },
    metadataFavorites: {
        id:'metadataFavorites',
        defaultMessage: 'Favorites',
        description: 'Label for # of Favorites',
    },
    metadataLanguage: {
        id:'metadataLanguage',
        defaultMessage: 'Language',
        description: 'Label for Language',
    },
    metadataLearningObjectives: {
        id:'metadataLearningObjectives',
        defaultMessage: 'Learning objectives',
        description: 'Heading for list of label objectives',
    },
    metadataLearningObjectiveListItem: {
        id:'metadataLearningObjectiveListItem',
        defaultMessage: 'List item {number}.',
        description: 'Ordered list item label for screen readers.',
    },
    metadataDeleteLearningObjective:  {
        id:'metadataDeleteLearningObjective',
        defaultMessage: 'Delete learning objective {number} button',
        description: 'Describes the delete button for non-visual users.',
    },
    metadataLicense: {
        id:'metadataLicense',
        defaultMessage: 'License',
        description: 'Label for License',
    },
    metadataRemixes: {
        id:'metadataRemixes',
        defaultMessage: 'Remixes',
        description: 'Label for # of Remixes',
    },
    metadataRemixesPublic: {
        id:'metadataRemixesPublic',
        defaultMessage: '{value} Public',
        description: 'Text for public remixes',
    },
    metadataRemixesPrivate: {
        id:'metadataRemixesPrivate',
        defaultMessage: '{value} Private',
        description: 'Text for private remixes',
    },
    metadataSubject: {
        id:'metadataSubject',
        defaultMessage: 'Subject',
        description: 'Label for Subject',
    },
    metadataSubtitles: {
        id:'metadataSubtitles',
        defaultMessage: 'Subtitles: {languages}',
        description: 'Label for a list of available subtitles',
    },
    metadataViews: {
        id:'metadataViews',
        defaultMessage: 'Views',
        description: 'Label for # of Views',
    },
    metadataClipVideos: {
        id: 'metadataClipVideos',
        defaultMessage: 'Clip video',
        description: 'Label for Clip video',
    },
    metadataVideoLengthTime: {
        id: 'metadataVideoLengthTime',
        defaultMessage: 'Video length time',
        description: 'Label next to the video length',
    },
    metadataVideoDuration: {
        id: 'metadataVideoDuration',
        defaultMessage: 'Video duration',
        description: 'Label for video duration',
    },
    removeSearchCriteria: {
        id:'removeSearchCriteria',
        defaultMessage: 'Remove this search criteria',
        description: 'Label for removing search criteria',
    },
    clearSearchCriteria: {
        id:'clearSearchCriteria',
        defaultMessage: 'Clear this search query.',
        description: 'Label for clearing search criteria',
    },
    searchError: {
        id:'searchError',
        defaultMessage: 'Unable to load search results.',
        description: 'Error message when a search fails.',
    },
    similarItemsTab: {
        id:'similarItemsTab',
        defaultMessage: 'Suggested',
        description: 'Label for similar items tab',
    },
    moreItemsBySourceTab: {
        id:'moreItemsBySourceTab',
        defaultMessage: 'More by this source',
        description: 'Label for more items by this Source tab',
    },
    favoritesDashboardTabTitle: {
        id:'favoritesDashboardTabTitle',
        defaultMessage: 'Favorites',
        description: 'Title of the dashboard favorites tab.',
    },
    favoritesEmpty: {
        id:'favoritesEmpty',
        defaultMessage: 'You don\'t have any favorites yet.',
        description: 'Favorites empty screen text.',
    },
    privateIconText: {
        id:'privateIconText',
        defaultMessage: 'Private',
        description: 'Text required for private icon',
    },
    visitLibrary: {
        id:'visitLibrary',
        defaultMessage: 'Visit library',
        description: 'Label on favorites dashboard for button to visit Library page',
    },
    exploreButtonText: {
        id:'exploreButtonText',
        defaultMessage: 'Explore',
        description: 'Label on favorites dashboard for button to visit Explore page',
    },
    orgLogoAltBadge: {
        id:'orgLogoAltBadge',
        defaultMessage: 'Logo for {name}',
        description: 'Alt text on organization logo badge logo images',
    },
    pathwayItemCurrent: {
        id:'pathwayItemCurrent',
        defaultMessage: 'Current item.',
        description: 'SR-only text to indicate that this item is the current item in the pathway',
    },
    pathwayItemComplete: {
        id:'pathwayItemComplete',
        defaultMessage: 'Completed item.',
        description: 'SR-only text to indicate that this item in the pathway is completed',
    },
    profileCardJobTitleWithCompany: {
        id:'profileCardJobTitleWithCompany',
        defaultMessage: '{jobTitle} at {company}',
        description: 'Text to be shown in profile card',
    },
    profileCardJobTitle: {
        id:'profileCardJobTitle',
        defaultMessage: '{jobTitle}',
        description: 'Text to be shown in profile card',
    },
    profileCardCompany: {
        id:'profileCardCompany',
        defaultMessage: '{company}',
        description: 'Text to be shown in profile card',
    },
    profileCardCountry: {
        id:'profileCardCountry',
        defaultMessage: '{country}',
        description: 'Text to be shown in profile card',
    },
    unsavedChangesLeaveConfirm: {
        id:'unsavedChangesLeaveConfirm',
        defaultMessage: 'Are you sure you want to leave without saving the changes to this pathway?',
        description: 'Custom text to be shown in the browser popup to confirm navigating away from a pathway edit page when there are unsaved changes.',
    },
    leavePublishModalTitlePrivate: {
        id:'leavePublishModalTitlePrivate',
        defaultMessage: 'Save pathway?',
        description: 'Title of the modal shown when saving and leaving a pathway to add/create new content',
    },
    leavePublishModalInfo: {
        id:'leavePublishModalInfo',
        defaultMessage: 'You are about to leave the pathway builder. All your work will be saved, and your new {assetType} will be added after you click Save and create.',
        description: 'Title of the modal shown when saving and leaving a pathway to add/create new content',
    },
    leavePublishWithSelectedItemsModalInfo: {
        id:'leavePublishWithSelectedItemsModalInfo',
        defaultMessage: 'You are about to leave the pathway builder. All your work will be saved. The selected content and new {assetType} will be added after you click Save and create.',
        description: 'Title of the modal shown when saving and leaving a pathway with selected items to add/create new content'
    },
    leavePublishModalBackToEditButton: {
        id:'leavePublishModalBackToEditButton',
        defaultMessage: 'Back to edit',
        description: 'Button to cancel and return to editing.',
    },
    leavePublishModalConfirmButton: {
        id:'leavePublishModalConfirmButton',
        defaultMessage: 'Save and create',
        description: 'Button to save pathway and continue to add new item.',
    },
    savingModalMessage: {
        id:'savingModalMessage',
        defaultMessage: 'Saving pathway',
        description: 'Text next to the loading when force saving the pathway',
    },
    cannotAddMoreItemsToPathway: {
        id:'cannotAddMoreItemsToPathway',
        defaultMessage: 'Cannot add more items. Pathways are limited to {maxItems} items.',
        description: 'Message shown when attempting to add more than the item limit to a pathway.',
    },
    caseStudyAttachmentsSectionTitle: {
        id:'caseStudyAttachmentsSectionTitle',
        defaultMessage: 'Content',
        description: 'Title of the attachments/content section of a case study block view.',
    },
    errorLoadingCaseStudy: {
        id:'errorLoadingCaseStudy',
        defaultMessage: 'Error while loading case study components.',
        description: 'Error message when an error occurs while loading components for a case study.',
    },
    errorLoadingTeachingGuide: {
        id:'errorLoadingTeachingGuide',
        defaultMessage: 'Error while loading teaching guide components.',
        description: 'Error message when an error occurs while loading components for a teaching guide.',
    },
    errorLoadingVideo: {
        id:'errorLoadingVideo',
        defaultMessage: 'Error while loading video component.',
        description: 'Error message when an error occur while loading video component.',
    },
    errorLoadingImage: {
        id: 'errorLoadingImage',
        defaultMessage: 'Error while loading image component {id}.',
        description: 'Error message when an error occur while loading image component with id.',
    },
    errorLoadingQuestion: {
        id: 'errorLoadingQuestion',
        defaultMessage: 'Error while loading question item.',
        description: 'Error message when an error occur while loading question.',
    },
    errorLoadingAudio: {
        id: 'errorLoadingAudio',
        defaultMessage: 'Error while loading audio item.',
        description: 'Error message when an error occur while loading audio.',
    },
    viewOwnerProfileButton: {
        id:'viewOwnerProfileButton',
        defaultMessage: 'Profile',
        description: 'Button that links the item owner profile.',
    },
    duplicatesConfirmDescription: {
        id:'duplicatesConfirmDescription',
        defaultMessage: 'You have already added these assets to your pathway. Do you want to add them again?',
        description: 'Description to be shown on the confirm adding duplicates modal.',
    },
    savingAnnotatedVideoModalTitle: {
        id:'savingAnnotatedVideoModalTitle',
        defaultMessage: 'Saving annotated video',
        description: 'Title for the saving annotated video modal.',
    },
    savingAnnotatedVideoModalText: {
        id:'savingAnnotatedVideoModalText',
        defaultMessage: 'Annotated videos are still in development so may take up to a minute to save. Please be patient.',
        description: 'Saving annotated video modal text.',
    },
    imageBlockExtendedDescription: {
        id:'imageBlockExtendedDescription',
        defaultMessage: 'Extended description',
        description: 'Extended description for an image block.',
    },
    clickToEnlarge: {
        id: 'clickToEnlarge',
        defaultMessage: 'Click to enlarge',
        description: 'Label on a button on an image to open a larger display of the image.',
    },
    uiPrivateAssetAlertTitle: {
        id: 'uiPrivateAssetAlertTitle',
        defaultMessage: 'You need an account to view this content',
        description: 'Title of the alert when the user enters to a private asset without being logged in',
    },
    uiPrivateAssetAlertBody: {
        id: 'uiPrivateAssetAlertBody',
        defaultMessage: 'This content is private and can only be accessed with a LabXchange account. Please log in or sign up to access this content.',
        description: 'Body content of the alert when the user enters to a private asset without being logged in',
    },
    accessibilityLink: {
        id: 'accessibilityLink',
        defaultMessage: 'Accessibility',
        description: 'Text on a link to display accessibility documentation.',
    },
    orgTypeCollaborator: {
        id: 'orgTypeCollab',
        defaultMessage: 'Collaborator',
        description: 'Label for collaborator organization type',
    },
    orgTypeContentSource: {
        id: 'orgTypeContentSource',
        defaultMessage: 'Content Source',
        description: 'Label for content source organization types',
    },
    showItemReferencesButtonLabel: {
        id: 'showItemReferencesButtonLabel',
        defaultMessage: 'Show References',
        description: 'Label of the button that allows user to view all instances where the current asset is referenced or used as a child item.',
    },
    copyAnnotatedVideoButtonLabel: {
        id: 'copyAnnotatedVideoButtonLabel',
        defaultMessage: 'Copy annotated video',
        description: 'Label of the button that allows user to clone an annotated video',
    },
    copyAnnotatedVideoTitle: {
        id: 'copyAnnotatedVideoTitle',
        defaultMessage: 'Copy annotated video',
        description: 'Title of the confirmation modal when the user wants to copy an annotated video',
    },
    copyAnnotatedVideoConfirm: {
        id: 'copyAnnotatedVideoConfirm',
        defaultMessage: 'Copy and annotate video',
        description: 'Label of the confirm button of the modal when the user wants to copy an annotated video',
    },
    copyAnnotatedVideoCancel: {
        id: 'copyAnnotatedVideoCancel',
        defaultMessage: 'Cancel',
        description: 'Label of the cancel button of the modal when the user wants to copy an annotated video',
    },
    copyAnnotatedVideoModalContent: {
        id: 'copyAnnotatedVideoModalContent',
        defaultMessage: 'Do you want to copy and edit this annotated video?',
        description: 'Content of the modal when the user wants to copy an annotated video',
    },
    hint: {
        id: 'hint',
        defaultMessage: 'Hint {n}',
        description: 'Button to toggle display nth hint.',
    },
    hintOne: {
        id: 'hintOne',
        defaultMessage: 'Hint',
        description: 'Button to toggle display hint.',
    },
    questionAttemptsLeft: {
        id: 'questionAttemptsLeft',
        defaultMessage: 'Attempts left: {attempts}',
        description: 'Message displaying number of answer attempts left for a question.',
    },
    optionResponseQuestionPlaceholder: {
        id: 'optionResponseQuestionPlaceholder',
        defaultMessage: '- Select -',
        description: 'Label in the dropdown/option list as a placeholder before user has selected an option.',
    },
    errorSubmittingAnswer: {
        id: 'errorSubmittingAnswer',
        defaultMessage: 'Failed to submit answer.  Please try again later.',
        description: 'Message shown when there was an error during the process of submitting an answer to a question.',
    },
    submitAnswer: {
        id: 'submitAnswer',
        defaultMessage: 'Submit answer',
        description: 'Label on button to submit answer to question.',
    },
    tryAgainButtonText: {
        id: 'tryAgainButtonText',
        defaultMessage: 'Try Again',
        description: 'Label on button to try again which will reset question state.',
    },
    getHintButtonText: {
        id: 'getHintButtonText',
        defaultMessage: 'Get Hint',
        description: 'Label on button to get hint.',
    },
    incorrectQestionnChoiceFeedbackDefault: {
        id: 'incorrectQestionnChoiceFeedbackDefault',
        defaultMessage: 'Not quite! You haven\'t selected all the correct answers. Try again.',
        description: 'General feedback message when answering a multiple choices question incorrectly.',
    },
    correctQestionnChoiceFeedbackDefault: {
        id: 'correctQestionnChoiceFeedbackDefault',
        defaultMessage: 'Well done! You\'ve selected all the correct answers.',
        description: 'General feedback message when answering a multiple choices question correctly.',
    },
    questionCorrect: {
        id: 'questionCorrect',
        defaultMessage: 'Correct!',
        description: 'General feedback message when answering a question correctly.',
    },
    questionIncorrect: {
        id: 'questionIncorrect',
        defaultMessage: 'Not quite. Try again.',
        description: 'General feedback message when answering a question incorrectly.',
    },
    questionIncorrectWithAnswer: {
        id: 'questionIncorrectWithAnswer',
        defaultMessage: 'Incorrect. The correct answer is "{answer}"',
        description: 'Message displayed when an incorrect answer was submitted, and the correct answer is given as feedback.',
    },
    questionIncorrectAttempts: {
        id: 'questionIncorrectAttempts',
        defaultMessage: `
        { remaining, plural,
            =0 {Incorrect. You have no attempts left.}
            one {You still have 1 attempt left, try again!}
            other {You still have # attempts left, try again!}
        }`,
        description: 'General feedback message when answering a question incorrectly and have a certain number of attempts remaining.',
    },
    incorrectFeedback: {
        id: 'incorrectFeedback',
        defaultMessage: 'Incorrect:',
        description: 'Message when question was answered incorrectly, and the question supplies a specific feedback comment.',
    },
    correctFeedback: {
        id: 'correctFeedback',
        defaultMessage: 'Correct:',
        description: 'Message when question was answered incorrectly, and the question supplies a specific feedback comment.',
    },
    stringAnswerPlaceholder: {
        id: 'stringAnswerPlaceholder',
        defaultMessage: 'Enter your answer here',
        description: 'Placeholder in the text input field for answering a short response question',
    },
    maxImageSizeAlertMessage: {
        id: 'maxImageSizeAlertMessage',
        defaultMessage: 'This image file is too large. The file size cannot exceed {maxSizeNumber} MB.',
        description: 'Body of the alert popup when the user tries to upload a big image',
    },
    updateWarningBodyMessage: {
        id: 'updateWarningBodyMessage',
        defaultMessage: 'This {itemType} will be updated on {date}. ',
        description: 'Body of the warning box when an item will be updated',
    },
    updateWarningLinkMessage: {
        id: 'updateWarningLinkMessage',
        defaultMessage: 'Learn more.',
        description: 'Link message of the warning box when an item will be updated'
    },
    assignButton: {
        id:'assignButton',
        defaultMessage: 'Assign',
        description: 'Assign button label.',
    },
    ariaFavoriteButton: {
        id:'ariaFavoriteButton',
        defaultMessage: 'Favorite',
        description: 'Aria label for favorite button on library card',
    },
    ariaViewButton: {
        id:'ariaViewButton',
        defaultMessage: 'View',
        description: 'Aria label for view button on library card',
    },
    ariaLaunchButton: {
        id:'ariaLaunchButton',
        defaultMessage: 'Launch',
        description: 'Aria label for launch button on library card',
    },
    newTage: {
        id:'newTage',
        defaultMessage: 'New',
        description: 'Text to show for new added content',
    },
    openGoogleDocLinkText: {
        id:'openGoogleDocLinkText',
        defaultMessage: 'Open Google Doc',
        description: 'Text to show that this will open link to google doc',
    },
    backToAssetButtonText: {
        id:'backToAssetButtonText',
        defaultMessage: 'Back to {itemType}',
        description: 'Text to show that this will take back to parent asset of resource',
    },
    simulationMobileBannerInfo: {
        id:'simulationMobileBannerInfo',
        defaultMessage: 'For the best experience, please open this simulation on a desktop computer. {link}',
        description: 'Text to show on banner when simulation is opened on mobile device',
    },
    simulationMobileBannerLearnMore: {
        id:'simulationMobileBannerLearnMore',
        defaultMessage: 'Learn more',
        description: 'Text to show on banner for the external more info link',
    },
    safariWarningBannerText: {
        id: 'safariWarningBannerText',
        defaultMessage: 'This simulation is not supported in Safari. Please switch to a different browser.',
        description: 'Text to show on warning banner when simulation is opened on Safari browser',
    },
});

export default messages;

/**
 * Convert a background knowledge value like 'some' to the human-readable/translated version ('Some')
 */
export function getBackgroundKnowledgeLabel(value: string): {defaultMessage: string, id: string} {
    return (messages as any)[('backgroundKnowledgeLabel_' + value)] || messages.backgroundKnowledgeLabel_;
}

/**
 * Convert an item's license field value like 'cc-by-sa' to the human-readable/translated version
 * ('Creative Commons Share-Alike')
 */
export function getContentLicenseLabel(value: string): {defaultMessage: string, id: string} {
    return (messages as any)['License name: ' + value] || messages.licenseNameUnknown;
}

/**
 * Get the URL to details of this content license
 * e.g. https://creativecommons.org/licenses/by-nc/4.0/deed.es for CC BY-NC 4 in Spanish
 */
export function getContentLicenseUrl(value: string): string {
    return ((messages as any)['License URL: ' + value] || messages['License URL: LX1']).defaultMessage;
}
