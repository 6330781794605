import React, { useState, useEffect } from 'react';
import messages from '../../../../displayMessages';
import { intl } from '../../../../../i18n';
import { Icon } from '../../../../../elements';
import {Skill, Task} from 'labxchange-client';
import SideBarParent from '../SideBarForms/SideBarParent';
import AbilityCardComponent from '../Abilities/AbilityCard';
import SkillCard from '../Skills/SkillCard';
import SkillSideBar from '../Skills/SkillSideBar';


interface DutiesSideBarProps {
    selectedTask: Task;
    selectedTaskNumber: number;
    isFullscreen: boolean;
    onClose: () => void;
    showSideBar: boolean;
}

const DutiesSideBar: React.FC<DutiesSideBarProps> = ({selectedTask, selectedTaskNumber, isFullscreen, showSideBar, onClose}) => {

    const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null);
    const [selectedSkillIndex, setSelectedSkillIndex] = useState(0);

    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(!expanded);
    const skillsToShow = expanded ? selectedTask.skills : selectedTask.skills?.slice(0, 3);

    const titleDescriptionComponent = (title:string, description:string)  => <div>
        <div className='title'> {title} </div>
        <div> {description} </div>
    </div>;

    const onSelectSkillCard = (skill: Skill, index: number) => {
        setSelectedSkill(skill);
        setSelectedSkillIndex(index+1);
    };

    useEffect( () => {
       setSelectedSkillIndex(0);
       setSelectedSkill(null);
    }, [selectedTask]);

    return (
        <>
            <SideBarParent
                heading={intl.formatMessage(messages.dutySideBarHeaderTitle, {count: selectedTaskNumber})}
                description={titleDescriptionComponent(selectedTask.longName, selectedTask.description)}
                onClose={onClose}
                isOpen={showSideBar}
                isFullScreen={isFullscreen}
                className='duties'
            >
                {selectedTask.assetLink &&
                  <a
                    href={selectedTask.assetLink}
                    className='asset-card'
                  >
                    <img
                      alt={intl.formatMessage(messages.altTextForImages)}
                      src={'/assets/images/career-explorer/labxchange-asset-logo.svg'}
                      className='logo'
                    />
                    <div className='asset-card-text'>
                        {intl.formatMessage(messages.dutyTasksAssetLinkText, {
                            b: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                        } as unknown as Record<string, React.ReactNode>)}
                    </div>

                    <div data-testid='openExternalIcon'>
                      <Icon name='link-external' fill='#00AEEF' zoom='1em'></Icon>
                    </div>
                  </a>
                }
                <div className='asset-border'></div>

                <div className='skills-section'>
                    <div className='main-content-title'>{intl.formatMessage(messages.skillsTitleText)}</div>
                    <div className='main-content-title-border-bottom'></div>
                    <div className='main-content-description'>{intl.formatMessage(messages.skillsDescriptionText)}</div>
                    <div className='skill-card-holder'>
                        {skillsToShow?.map((skill, index) => (
                            <SkillCard
                                count={index+1}
                                description={skill.description}
                                key={skill.title}
                                onClick={() => onSelectSkillCard(skill, index)}
                            />
                        ))}
                    </div>
                    {selectedTask.skills && selectedTask.skills.length > 3 && (
                        <button className='toggle-button' onClick={toggleExpanded}>
                            {expanded
                                ? intl.formatMessage(messages.collapseSkillsText)
                                : intl.formatMessage(messages.showAllSkillsText)}
                        </button>
                    )}
                </div>

                <div className='skills-section'>
                    <div className='main-content-title'>{intl.formatMessage(messages.abilityTitleText)}</div>
                    <div className='main-content-title-border-bottom'></div>
                    <div className='main-content-description'>{intl.formatMessage(messages.abilitiesDescriptionText)}</div>
                    <div className='ability-card-holder'>
                        {selectedTask.abilities?.map((ability) => (
                            <AbilityCardComponent
                                title={ability.title}
                                description={ability.description}
                                userPromptText={intl.formatMessage(messages.abilityAcquiredText)}
                                key={ability.title}
                            />
                        ))}
                    </div>
                </div>
            </SideBarParent>
            {selectedSkill &&
              <SkillSideBar
                skill={selectedSkill}
                isFullscreen={isFullscreen}
                selectedSkillIndex={selectedSkillIndex}
                onClose={()=> setSelectedSkill(null)}
              />
            }
        </>
    );
};

export default DutiesSideBar;
