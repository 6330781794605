import * as React from 'react';
import { intl } from 'i18n';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ItemResponse, ModuleSlab, SectionSlab } from 'labxchange-client';
import { Icon } from 'elements';
import { Card, CardProps } from 'library/components';
import { detailUrlForEntity } from 'library/utils';
import { CardSkeleton } from 'home/components/TeachPage';
import uiMessages from 'ui/components/displayMessages';
import { useLayoutSize } from 'utils';

interface ClassicCardsSlabProps {
    slab?: SectionSlab | ModuleSlab;
    heading: string;
    description?: string;
    moreLink?: string;
    moreText?: string;
    showFeedbackButton?: boolean;
    curriculumSlug?: string;
    module?: number;
}

export const ClassicCardsSlab: React.FC<ClassicCardsSlabProps> = (props) => {
  const layoutSize = useLayoutSize();
  const cardsStateFromData = (slab?: SectionSlab | ModuleSlab) => {
    if (!slab || !slab.items) return ([]);
      const items: ItemResponse[] = slab.items;
      const cardsDetail: CardProps[] = items.map((item: ItemResponse) => {
          const result: CardProps = {
              detailUrl: detailUrlForEntity(item.metadata),
              metadata: item.metadata,
              userAttributes: item.userAttributes,
              mobileViewMode: true,
              showMenuButton: true,
              isPublicContentOnly: true,
          };
          return result;
      });
      return cardsDetail;
  };

  const getCardsPerPage = (cardsLength: number): number => {
    if (layoutSize === 'mobile' || layoutSize === 'medium') {
      return cardsLength;
    } else if (layoutSize === 'large') {
      return 3;
    } else {
      return 4;
    }
  };

  const cards = cardsStateFromData(props.slab);
  const [currentPage, setCurrentPage] = React.useState(0);
  const cardsPerPage = getCardsPerPage(cards.length);
  const totalPages = Math.ceil(cards.length / cardsPerPage);

  const handleNextClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderSkeleton = () => {
      return Array.from({ length: cardsPerPage }).map((_, index) => (
        <li key={index}>
          <CardSkeleton />
        </li>
      ));
  };

  const startIndex = currentPage * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const displayedCards = cards.slice(startIndex, endIndex);

  return (
        <div className='classic-cards-container section'>
          <div className='more-header'>
            <h1 className='slab-title'>{props.heading}</h1>
            {props.moreLink && props.moreText && (
              <Link to={props.moreLink} className='more-link'>{props.moreText}</Link>
            )}
          </div>
          <div className='list-cards'>
            <button className={classNames('btn-previous', {'hide': currentPage === 0 })}
                title={intl.formatMessage(uiMessages.uiPrevious)}
                onClick={handlePrevClick}
                >
                <Icon name='chevron-left' />
            </button>
            <ul className={classNames('cards-ul list-unstyled', {'curriculum-page' : displayedCards.length < 4})}>
                {
                  displayedCards.length === 0 ? renderSkeleton() :
                  <>
                    {displayedCards.map((cardProps, index) => (
                        <li key={index} >
                          <Card
                            {...cardProps}
                            showFeedbackButton={props.showFeedbackButton}
                            curriculumSlug={props.curriculumSlug}
                            module={props.module}
                          />
                        </li>
                    ))}
                  </>
                }
            </ul>
            <button className={classNames('btn-next', {'hide': currentPage === totalPages - 1 })}
                title={intl.formatMessage(uiMessages.uiNext)}
                onClick={handleNextClick}
            >
            <Icon name='chevron-right'/>
            </button>
          </div>
    </div>
  );
};

export default ClassicCardsSlab;
