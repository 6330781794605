import React from 'react';
import { Icon } from '../../../elements';
import { ROUTES } from '../../../global/constants';
import { NavLink } from 'react-router-dom';

const Navbar: React.FC = () => {
    return (
        <nav className='navbar-career'>
            <NavLink to={ROUTES.CareerExplorer.HOME}>
                <div className='navbar__icon'>
                {/* need to insert icons here */}
                </div>
            </NavLink>
            <div className='navbar__title'>Career Explorer</div>
            <div className='navbar__icon'>
                {/* need to insert icons here */}
            </div>
        </nav>
    );
};

export default Navbar;
