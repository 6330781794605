// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AssetFeedbackResponse
 */
export interface AssetFeedbackResponse {
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackResponse
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackResponse
     */
    curriculum: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackResponse
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackResponse
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFeedbackResponse
     */
    grade?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetFeedbackResponse
     */
    readonly modules?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetFeedbackResponse
     */
    readonly tags?: Array<string>;
    /**
     * Whether the user found the content useful.
     * @type {boolean}
     * @memberof AssetFeedbackResponse
     */
    isContentUseful: boolean;
    /**
     * Additional feedback from the user.
     * @type {string}
     * @memberof AssetFeedbackResponse
     */
    tellUsMoreFeedback?: string;
    /**
     * Whether this feedback was submitted using the feedback modal on the module page.                     Feedback submitted on the module page is specific to a particular module as compared                     to asset feedback submitted from other places (e.g. the asset page) which can be                     against many different modules, since it's for the entire curriculum
     * @type {boolean}
     * @memberof AssetFeedbackResponse
     */
    isModulePageFeedback?: boolean;
    /**
     * The time the feedback was created.
     * @type {Date}
     * @memberof AssetFeedbackResponse
     */
    readonly createdAt?: Date;
    /**
     * The time the feedback was last updated.
     * @type {Date}
     * @memberof AssetFeedbackResponse
     */
    readonly updatedAt?: Date;
}

export function AssetFeedbackResponseFromJSON(json: any): AssetFeedbackResponse {
    return {
        'asset': json['asset'],
        'curriculum': json['curriculum'],
        'user': json['user'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'grade': !exists(json, 'grade') ? undefined : json['grade'],
        'modules': !exists(json, 'modules') ? undefined : json['modules'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'isContentUseful': json['is_content_useful'],
        'tellUsMoreFeedback': !exists(json, 'tell_us_more_feedback') ? undefined : json['tell_us_more_feedback'],
        'isModulePageFeedback': !exists(json, 'is_module_page_feedback') ? undefined : json['is_module_page_feedback'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function AssetFeedbackResponseToJSON(value?: AssetFeedbackResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'asset': value.asset,
        'curriculum': value.curriculum,
        'user': value.user,
        'subject': value.subject,
        'grade': value.grade,
        'is_content_useful': value.isContentUseful,
        'tell_us_more_feedback': value.tellUsMoreFeedback,
        'is_module_page_feedback': value.isModulePageFeedback,
    };
}


