import React, { useState, useEffect, useRef } from 'react';
import videojs from 'video.js';
import classNames from 'classnames';

interface VideoPlayerProps {
    videoUrl: string;
    posterUrl?: string; // Add a prop for the fallback image
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, posterUrl }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (videoRef.current) {
            const player = videojs(videoRef.current, {
                controls: true,
                autoplay: false,
                preload: 'auto',
                sources: [{ src: videoUrl, type: 'application/x-mpegURL' }],
                controlBar: {
                    fullscreenToggle: false,
                },
            });

            const handlePlay = () => setIsPlaying(true);
            const handlePause = () => setIsPlaying(false);
            const handleLoadedData = () => setIsLoading(false);

            player.on('play', handlePlay);
            player.on('pause', handlePause);
            player.on('loadeddata', handleLoadedData); // Video data has loaded

            return () => {
                player.off('play', handlePlay);
                player.off('pause', handlePause);
                player.off('loadeddata', handleLoadedData);
                player.dispose();
            };
        } else return;
    }, [videoUrl]);

    return (
        <div data-vjs-player>
            {isLoading && (
                <img
                    src={posterUrl}
                    alt='Loading placeholder'
                    className='video-poster'
                    style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}
                />
            )}
            <video
                ref={videoRef}
                className={classNames('video-js vjs-lx-skin', { 'video-playing': isPlaying })}
                playsInline
                data-testid='video-element'
                poster={posterUrl} // Fallback image shown before play starts
            />
        </div>
    );
};

export default VideoPlayer;
