import React from 'react';
import { EdgeProps } from 'reactflow';

const CustomEdge: React.FC<EdgeProps> = ({ id, data, sourceX, sourceY, targetX, targetY }) => {
  const edgePath = `M${sourceX},${sourceY} L${targetX},${targetY}`;

  return (
    <g className='react-flow__edge'>
      <path
        id={id}
        className={`react-flow__edge-path ${data.edgeType}`}
        d={edgePath}
      />
    </g>
  );
};

export default CustomEdge;
