import * as React from 'react';
import { useSelector } from 'react-redux';
import Select, { ValueType } from 'react-select';
import { intl } from 'i18n';
import classNames from 'classnames';

import {
  CurriculumSubject,
  ModuleHierarchy
} from 'labxchange-client';
import { AssetFeedbacksApi, CurriculumsApi } from 'global/api';
import { Icon, Modal } from 'elements';
import {
  Button, Spinner, Tag, TextBox, showErrorMessage,
  TreeDropdown,
} from 'ui/components';
import { WrappedMessage } from 'utils';
import { getUserEmail } from 'auth/selectors';
import messages from '../displayMessages';
import { SelectDropdownOption } from 'curriculum/types';
import { createSubjectGradeOptions } from 'curriculum/utils';

export enum IS_CONTENT_USEFUL {
  YES = 'Yes',
  NO = 'No',
}

export const WHY_TAGS = [
  'Too Advanced', 'Too Easy', 'Too Broad', 'Too Specific',
  'Not Relevant for My Lessons', 'Other',
];

interface FeedbackModalProps {
  assetId: string;
  curriculumSlug: string;
  onClose: () => void;
  onSubmit: () => void;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  assetId, onClose, onSubmit, curriculumSlug,
}) => {
  const [isContentUseful, setIsContentUseful] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const userEmail = useSelector(getUserEmail);
  const [curriculumSubjects, setCurriculumSubjects] = React.useState<CurriculumSubject[]>([]);
  const [curriculumModules, setCurriculumModules] = React.useState<ModuleHierarchy[]>([]);
  const [selectedSubject, setSelectedSubject] = React.useState<string>('');
  const [selectedGrade, setSelectedGrade] = React.useState<string>('');
  const [loadingModules, setLoadingModules] = React.useState<boolean>(false);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [tellUsMoreFeedback, setTellUsMoreFeedback] = React.useState<string>('');
  const [selectedModules, setSelectedModules] = React.useState<number[]>([]);
  const otherTagSelected = selectedTags.includes('Other');
  const modulesSelected = selectedModules.length > 0;

  let submitDisabled = true;
  if (isContentUseful === IS_CONTENT_USEFUL.YES) {
    submitDisabled = selectedGrade && selectedSubject && modulesSelected ? false : true;
  } else if (isContentUseful === IS_CONTENT_USEFUL.NO) {
    submitDisabled = !selectedTags.length || (otherTagSelected && !tellUsMoreFeedback);
  }

  React.useEffect(() => {
    const fetchCurriculumDetails = async () => {
      try {
        setLoading(true);
        const response = await CurriculumsApi.getDetail({
          id: curriculumSlug,
        });
        setCurriculumSubjects(response?.subjects || []);
        setLoading(false);
      } catch (error) {
        showErrorMessage(intl.formatMessage(messages.feedbackModalErrorCurriculumDetails));
      }
    };
    fetchCurriculumDetails();
  } , []);

  React.useEffect(() => {
    const fetchModules = async () => {
      try {
        setLoadingModules(true);
        const response = await CurriculumsApi.read({
          id: curriculumSlug,
          subject: selectedSubject,
          grade: selectedGrade,
        });
        setCurriculumModules(response?.modules || []);
        setLoadingModules(false);
      } catch (error) {
        showErrorMessage(intl.formatMessage(messages.feedbackModalErrorModules));
      }
    };

    if (selectedSubject && selectedGrade) {
      fetchModules();
    }
  }
  , [selectedSubject, selectedGrade]);

  const registerFeedback = async () => {
    const response = await AssetFeedbacksApi.feedbacksCreate({
      data: {
        asset: assetId,
        curriculum: curriculumSlug,
        userEmail,
        isContentUseful: isContentUseful === IS_CONTENT_USEFUL.YES,
        subject: selectedSubject,
        grade: selectedGrade,
        modules: selectedModules,
        tags: selectedTags,
        tellUsMoreFeedback,
        isModulePageFeedback: false,
      },
    });
    return response;
  };

  const onClickSubmitButton = async () => {
    setLoading(true);
    await registerFeedback();
    setLoading(false);
    onSubmit();
    onClose();
  };

  const clearState = () => {
    setSelectedSubject('');
    setSelectedGrade('');
    setSelectedModules([]);
    setSelectedTags([]);
    setTellUsMoreFeedback('');
  };

  const customDropdownStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 10001 }),
    menu: (base: any) => ({ ...base, zIndex: 10001 })
  };

  const onSubjectChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedSubjectValue = (selectedOption as SelectDropdownOption).value;
      setSelectedSubject(selectedSubjectValue);
      setSelectedModules([]);
    }
  };

  const onGradeChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedGradeValue = (selectedOption as SelectDropdownOption).value;
      setSelectedGrade(selectedGradeValue);
      setSelectedModules([]);
    }
  };

  const onModuleChange = (currentNode: any, selectedNodes: any) => {
    setSelectedModules(selectedNodes.map((node: any) => node.value));
  };

  const {
    subjectOptArray: subjectOptions,
    gradeOptArray: gradeOptions
  } = createSubjectGradeOptions(curriculumSubjects, selectedSubject);

  const formatModules: any = (modules: ModuleHierarchy[]) => {
    return modules.map((module: any) => ({
      label: module.name,
      value: module.id,
      children: formatModules(module.subModules || module.sub_modules || []),
      expanded: true,
    }));
  };

  const usefulContentFeedbackBody = () => {
    return (
      <>
        <div className='fb-mdl-section'>
            <span className='font-xxs-lt'>
              <WrappedMessage message={messages.feedbackModalSubjectGradeQuestion} />
              <div className='subject-grade-dropdowns'>
                <Select
                  tabIndex='0'
                  aria-label={intl.formatMessage(messages.feedbackModalSubjectDropdownAria)}
                  className='fb-mdl-dropdown'
                  classNamePrefix='select'
                  options={subjectOptions}
                  onChange={onSubjectChange}
                  isSearchable={false}
                  placeholder={intl.formatMessage(messages.feedbackModalSubjectDropdownPlaceholder)}
                  styles={customDropdownStyles}
                  defaultValue={subjectOptions.find(option => option.value === selectedSubject)}
                  menuPortalTarget={document.body}
                  menuPosition='fixed'
                />
                <Select
                  tabIndex='0'
                  aria-label={intl.formatMessage(messages.feedbackModalGradeDropdownAria)}
                  className='fb-mdl-dropdown'
                  classNamePrefix='select'
                  options={gradeOptions}
                  onChange={onGradeChange}
                  isSearchable={false}
                  placeholder={intl.formatMessage(messages.feedbackModalGradeDropdownPlaceholder)}
                  styles={customDropdownStyles}
                  defaultValue={gradeOptions.find(option => option.value === selectedGrade)}
                  menuPortalTarget={document.body}
                  menuPosition='fixed'
                  isDisabled={!selectedSubject}
                />
              </div>
            </span>
          </div>
          {selectedGrade && selectedSubject && curriculumModules.length > 0 && !loadingModules && (
            <div className={classNames('fb-mdl-section', { 'modules-selected': modulesSelected })}>
              <span className='font-xxs-lt'>
                <WrappedMessage message={messages.feedbackModalCurriculumQuestion} />
              </span>
              <TreeDropdown
                data={formatModules(curriculumModules)}
                onChange={onModuleChange}
                texts={{
                  placeholder: intl.formatMessage(messages.feedbackModalPODropdownPlaceholder),
                  inlineSearchPlaceholder: intl.formatMessage(
                    messages.feedbackModalPODropdownSearchPlaceholder
                  ),
                }}
                mode='hierarchical'
              />
            </div>
          )}
          {loadingModules && <div className='spinner-wrapper'><Spinner /></div>}
          {modulesSelected && (
            <div className='fb-mdl-section'>
              <span className='font-xxs-lt'>
                <WrappedMessage message={messages.feedbackModalPleaseTellUsMorOptionalLabel} />
              </span>
              <div className='please-tell-us-more-opt'>
                <TextBox
                  value={tellUsMoreFeedback}
                  label={
                      <WrappedMessage
                          message={messages.feedbackModalPleaseTellUsMoreLabel}
                      />
                  }
                  hideLabel={true}
                  onChange={(currentValue) => setTellUsMoreFeedback(currentValue)}
                  placeholder={intl.formatMessage(messages.feedbackModalPleaseTellUsMoreLabel)}
                />
              </div>
            </div>
          )}
      </>
    );
  };

  const toggleTag = (tag: string) => {
    setSelectedTags(prevSelectedTags => {
      if (prevSelectedTags.includes(tag)) {
        return prevSelectedTags.filter(t => t !== tag);
      } else {
        return [...prevSelectedTags, tag];
      }
    });
  };

  const notUsefulContentFeedbackBody = () => {
    return (
      <div className='fb-mdl-section'>
        <div className='why-title'>
          <span className='font-xxs-lt'>
            {intl.formatMessage(messages.feedbackModalWhy)}
          </span>
          <span className='why-placeholder'>
            {intl.formatMessage(messages.feedbackModalWhyPlaceholder)}
          </span>
        </div>
        <div className='why-tags'>
          {WHY_TAGS.map((tag: string) => (
            <Tag
              key={tag}
              onClick={() => toggleTag(tag)}
              disableIcon={true}
              active={selectedTags.includes(tag)}
            >
              {tag}
            </Tag>
          ))}
        </div>
        {otherTagSelected && (
          <div className='please-tell-us-more-useful'>
            <TextBox
              value={tellUsMoreFeedback}
              label={
                  <WrappedMessage
                      message={messages.feedbackModalPleaseTellUsMoreLabel}
                  />
              }
              hideLabel={true}
              onChange={(currentValue) => setTellUsMoreFeedback(currentValue)}
              placeholder={intl.formatMessage(messages.feedbackModalPleaseTellUsMoreLabel)}
            />
          </div>
        )}
      </div>
    );
  };

  const modalContent = (
    <div className='feedback-modal-body'>
      <h3 className='fb-mdl-title font-m-lt'>
        {intl.formatMessage(messages.feedbackModalTitle)}
      </h3>
      {loading ? <div className='spinner-wrapper'><Spinner /></div> : (
        <>
          <div className='form'>
            <div className='fb-mdl-section'>
              <span className='font-xxs-lt'>
                <WrappedMessage message={messages.feedbackModalIsContentUsefulQuestion} />
              </span>
              <div className='fb-mdl-buttons'>
                <button
                  className={classNames('fb-mdl-btn',
                    { 'is-active': isContentUseful === IS_CONTENT_USEFUL.YES },
                    { 'disabled': loading || loadingModules }
                  )}
                  aria-label={intl.formatMessage(messages.feedbackModalYesBtnLabel)}
                  onClick={() => {setIsContentUseful(IS_CONTENT_USEFUL.YES); clearState();}}
                  disabled={loading || loadingModules}
                >
                  <Icon name='thumbs-up' />
                  <span>{intl.formatMessage(messages.feedbackModalYesBtnLabel)}</span>
                </button>
                <button
                  className={classNames('fb-mdl-btn no-button',
                    { 'is-active': isContentUseful === IS_CONTENT_USEFUL.NO },
                    { 'disabled': loading || loadingModules }
                  )}
                  aria-label={intl.formatMessage(messages.feedbackModalNoBtnLabel)}
                  onClick={() => {setIsContentUseful(IS_CONTENT_USEFUL.NO); clearState();}}
                  disabled={loading || loadingModules}
                >
                  <Icon name='thumbs-down' />
                  <span>{intl.formatMessage(messages.feedbackModalNoBtnLabel)}</span>
                </button>
              </div>
            </div>
            {isContentUseful === IS_CONTENT_USEFUL.YES && usefulContentFeedbackBody()}
            {isContentUseful === IS_CONTENT_USEFUL.NO && notUsefulContentFeedbackBody()}
          </div>
          <div className='fb-mdl-footer'>
            <Button
              className='fb-mdl-submit-btn'
              btnStyle='primary'
              aria-label={messages.feedbackModalSubmitBtnLabel}
              label={messages.feedbackModalSubmitBtnLabel}
              disabled={submitDisabled || loading}
              onClick={onClickSubmitButton}
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <Modal
      className='feedback-modal'
      size='normal'
      topBarStyle='white'
      showBackButton={false}
      onRequestClose={onClose}
      showTopBar={true}
      content={modalContent}
    />
  );
};

export default FeedbackModal;
