import * as React from 'react';
import { FeaturedItems, ItemResponse } from 'labxchange-client';
import { CardProps } from 'library/components';
import { detailUrlForEntity } from 'library/utils';
import { CardDetail, CardSkeleton } from './CardDetail';
import { Icon } from 'elements';
import { intl } from 'i18n';
import uiMessages from 'ui/components/displayMessages';
import classNames from 'classnames';

interface CardsListProps {
    cards?: FeaturedItems;
    layoutSize: string;
    showFeedbackButton?: boolean;
    curriculumSlug?: string;
    module?: number;
}

export const CardsList: React.FC<CardsListProps> = (props) => {

    const cardsStateFromData = (cardsData?: FeaturedItems) => {
        if (cardsData === undefined) {return ([]); }
        const items = cardsData.items;
        const cardsDetail: CardProps[] = items.map((item: ItemResponse) => {
            const result: CardProps = {
                detailUrl: detailUrlForEntity(item.metadata),
                metadata: item.metadata,
                userAttributes: item.userAttributes,
                mobileViewMode: true,
                showMenuButton: true,
                isPublicContentOnly: true,
            };
            return result;
        });

        return cardsDetail;
    };

    const  cards = cardsStateFromData(props.cards);
    const [currentPage, setCurrentPage] = React.useState(0);

    const cardsPerPage = props.layoutSize === 'desktop' || props.layoutSize === 'large' ? 4 : cards.length;
    const totalPages = Math.ceil(cards.length / cardsPerPage);

    const handleNextClick = () => {
      if (currentPage < totalPages - 1) {
        setCurrentPage(currentPage + 1);
      }
    };

    const handlePrevClick = () => {
      if (currentPage > 0) {
        setCurrentPage(currentPage - 1);
      }
    };

    const renderSkeleton = () => {
        return Array.from({ length: 4 }).map((_, index) => (
          <li key={index}>
            <CardSkeleton />
          </li>
        ));
    };

    const startIndex = currentPage * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    const displayedCards = cards.slice(startIndex, endIndex);

    return (
        <>
            <button className={`btn-previous ${currentPage === 0 ? 'hide' : ''}`}
                title={intl.formatMessage(uiMessages.uiPrevious)}
                onClick={handlePrevClick}
                >
                <Icon name='chevron-left' />
            </button>
            <ul className={classNames('cards-ul list-unstyled', {'curriculum-page' : displayedCards.length < 4})}>
                {displayedCards.length === 0 ? renderSkeleton() : <>
                {displayedCards.map((cardProps) => (
                    <li key={cardProps.metadata && cardProps.metadata.id} >
                      <CardDetail
                        card={cardProps}
                        showFeedbackButton={props.showFeedbackButton}
                        curriculumSlug={props.curriculumSlug}
                        module={props.module}
                      />
                    </li>
                ))}
                </>

                }
            </ul>
            <button className={`btn-next ${currentPage === totalPages - 1 ? 'hide' : ''}`}
                title={intl.formatMessage(uiMessages.uiNext)}
                onClick={handleNextClick}
            >
            <Icon name='chevron-right'/>
            </button>
        </>
    );
};
