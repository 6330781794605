import * as React from 'react';
import { CardProps, ItemIcon } from 'library/components';
import { WrappedMessage } from 'utils';
import { ItemType, getItemTypeMeta } from 'items/models';
import { Link } from 'react-router-dom';
import { Icon } from 'elements';
import messages from './displayMessages';
import Skeleton from 'react-loading-skeleton';
import { FeedbackButton, FeedbackModal, FeedbackModalModulePage } from 'ui/components';

interface CardDetailProps {
    card?: CardProps;
    showFeedbackButton?: boolean;
    curriculumSlug?: string;
    module?: number;
}

export const CardDetail: React.FC<CardDetailProps> = ({
  card, showFeedbackButton, curriculumSlug, module,
}) => {
    const itemType = card && card.metadata ? getItemTypeMeta(card.metadata.type) : undefined;
    const cardOverViewURL = card && card.detailUrl ? card.detailUrl : '#';
    const cardLaunchURL = card && card.detailUrl ? `${card.detailUrl}?fullscreen=true` : '#';
    const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);
    const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false);

    const defaultImage = '/assets/images/default-featured.png';
    const assetId = card && card.metadata ? card.metadata.id : '';
    const assetIdOnS3 = assetId.replace(/:/g, '-');
    const gifUrl = `https://media.labxchange.org/interactive_gifs/${assetIdOnS3}.gif`;

    const cardImageUrl = card && card.metadata ? card.metadata.imageUrl : defaultImage;
    const [backgroundImage, setBackgroundImage] = React.useState(`url(${cardImageUrl})`);

    const handleMouseEnter = () => {
      const gif = new Image();
      gif.src = gifUrl;
      gif.onload = () => {
        setBackgroundImage(`url(${gifUrl})`);
      };
    };

    const handleMouseLeave = () => {
      setBackgroundImage(`url(${cardImageUrl})`);
    };

    const renderFeedbackModal = () => {
      if (showFeedbackModal && curriculumSlug && module && card && card.metadata) {
        return <FeedbackModalModulePage
          assetId={card?.metadata?.id}
          curriculumSlug={curriculumSlug}
          module={module}
          onClose={() => setShowFeedbackModal(false)}
          onSubmit={() => setFeedbackSubmitted(true)}
        />;
      } else if (showFeedbackModal && curriculumSlug && card && card.metadata) {
        return <FeedbackModal
          assetId={card?.metadata?.id}
          curriculumSlug={curriculumSlug}
          onClose={() => setShowFeedbackModal(false)}
          onSubmit={() => setFeedbackSubmitted(true)}
        />;
      }

      return <></>;
    };

    return (
        <div className='card-details' data-testid='card'>
          <Link to={cardLaunchURL} className='card-link'>
            <div className='card-info'
                role='presentation'
                style={{backgroundImage}}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
              {itemType && <div className='card-type'>
                  <ItemIcon itemType={ItemType.Interactive} zoom='14'/>
                  <span>
                      <WrappedMessage
                          message={itemType.name}
                      />
                  </span>
              </div>}
              <span className='title'>
                  {card?.metadata?.title}
              </span>
            </div>
          </Link>
          <div className='card-buttons'>
              <Link to={cardOverViewURL} className='overview'>
                  <WrappedMessage message={messages.overviewButtonText} />
              </Link>
              <Link to={cardLaunchURL} className='launch'>
                  <WrappedMessage message={messages.launchButtonText} />
                  <Icon name='polygon-right-curved'></Icon>
              </Link>
          </div>
          {showFeedbackButton &&
            <>
              <FeedbackButton
                position='inline'
                variant={
                  (
                    feedbackSubmitted ||
                    card?.userAttributes?.hasUserSubmittedFeedback
                  ) ? 'submitted' : 'pending'
                }
                onClick={() => setShowFeedbackModal(true)}
              />
              {renderFeedbackModal()}
            </>
          }
        </div>
    );
};


export const CardSkeleton: React.FC = () => {
    return (
      <div className='card-details'>
        <div className='card-info-skeleton'>
          <Skeleton width={90} height={24} />
        </div>
        <div className='card-buttons-skeleton'>
          <button className='overview'>
            <Skeleton width={80} height={16} />
          </button>
          <button className='launch'>
            <Skeleton width={80} height={16} />
          </button>
        </div>
      </div>
    );
};
