import React from 'react';
import { Icon } from '../../../../elements';
import { RelatedCareerSection } from 'labxchange-client';
import {WrappedMessage} from '../../../../utils';
import messages from '../../../displayMessages';
import { ROUTES } from 'global/constants';
import PulseCard from 'career-explorer/components/PulseCard';
import { useHistory } from 'react-router-dom';

interface RelatedCareerCardProps {
    title: string;
    description: string;
    icon?: string;
    slug: string;
}

export const RelatedCareerCard: React.FC<RelatedCareerCardProps> = ({ title, description, icon, slug }) => {
    const history = useHistory();

    const onClickCard = () => {
        setTimeout(() => {
            history.push(ROUTES.CareerExplorer.CAREER_DETAILS_SLUG(slug));
        }, 700);
    };
    return (
        <PulseCard onClick={onClickCard}>
            <div className='related-career'>
                <div className='related-career-icon'>
                    <img src={icon} />
                </div>
                <div className='related-career-content'>
                    <div className='related-career-title'>
                        <strong className='title'>{title}</strong>
                        <button className='save-career' type='button'>
                            <Icon name='bookmark' fill='#7188C7' />
                        </button>
                    </div>
                    <div className='related-career-description'>
                        {description}
                    </div>
                    <a className='learn-more' href='#' onClick={onClickCard}>
                        <WrappedMessage message={messages.careerDescriptionLearnMore} />
                    </a>
                </div>
            </div>
        </PulseCard>
    );
};


interface RelatedCareerProps {
    relatedCareerSections: RelatedCareerSection[];
}

const RelatedCareers: React.FC<RelatedCareerProps> = ({relatedCareerSections}) => {

    const defaultIcon = '/assets/images/career-explorer/biotech.svg';
    return (
        <div className='related-career-section'>
            <h2> <WrappedMessage message={messages.relatedCareersHeadingText} /> </h2>
            {relatedCareerSections.map((relatedSection, i) => (
                <div className='related-careers'>
                    <span>{relatedSection.sectionTitle}</span>
                    <div className='related-career-list' key={i}>
                        {relatedSection.relatedCareers.map((career)=>
                            <RelatedCareerCard
                                key={career.slug}
                                title={career.title}
                                description={career.description}
                                icon={defaultIcon}
                                slug={career.slug}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RelatedCareers;
