import React, { useEffect, useState, createRef } from 'react';

import { CareersApi } from 'global/api';
import { Career } from 'labxchange-client';

import Navbar from '../../CareerNavbar/CareerNavbar';
import CareerHeader from '../CareerDetailsHeader/CareerDetailsHeader';
import CareerIntroduction from './CareerIntroduction';
import CareerDuties from './Duties/CareerDuties';
import { useParams } from 'react-router-dom';
import CareerOutlook from './CareerOutlook';
import CareerSalary from './CareerSalary';
import ProjectHighlight from './ProjectHighlight';
import { EducationPathway } from './EducationPathway';
import CareerOverview from './CareerOverview';
import SourcesSection from './Sources';
import RelatedCareers from './RelatedCareers';
import CareerDetailsSkeleton from './Skeleton';
import { toggleHelpCenterVisibility } from '../../../../utils';

const CareerDetailsPage: React.FC = () => {
    const {careerSlug} = useParams<{ careerSlug: string }>();

    const navLinks = [
        {name: 'Introduction', href: '#introduction'},
        {name: 'Overview', href: '#overview'},
        {name: 'Duties', href: '#duties'},
        {name: 'Outlook', href: '#outlook'},
        {name: 'Education', href: '#education-pathway'},
        {name: 'Salary', href: '#salary'},
        {name: 'Related', href: '#related-careers'},
        {name: 'Sources', href: '#sources'},
    ];

    // Create refs for each section
    const introductionRef = createRef<HTMLDivElement>();
    const overviewRef = createRef<HTMLDivElement>();
    const dutiesRef = createRef<HTMLDivElement>();
    const outlookRef = createRef<HTMLDivElement>();
    const salaryRef = createRef<HTMLDivElement>();
    const relatedCareers = createRef<HTMLDivElement>();
    const educationPathwayRef = createRef<HTMLDivElement>();
    const sourcesRef = createRef<HTMLDivElement>();

    const [loading, setLoading] = useState(true);
    const [career, setCareer] = useState<Career>();

    useEffect(() => {
        toggleHelpCenterVisibility('hide');
        return () => {
            toggleHelpCenterVisibility('show');
        };
    }, []);

    useEffect(() => {
        const fetchCareer = async () => {
            try {
                setLoading(true);
                const response: Career = await CareersApi.read({id: careerSlug});
                setCareer(response);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchCareer();
    }, []);

    const handleNavClick = (href: string) => {
        const section = document.querySelector(href);
        if (section) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    };

    const showSalarySection = career?.entryLevelSalary && career?.midLevelSalary && career.seniorLevelSalary;

    return (
        <div className='career-details-home'>
            <Navbar/>
            {loading ? <CareerDetailsSkeleton /> :
              career &&
                <>
                  <CareerHeader careerName={career.title} navLinks={navLinks} onNavClick={handleNavClick}/>
                  <div className='career-details-section'>
                    <div className='sub-section' ref={introductionRef} id='introduction'>
                      <CareerIntroduction
                        description={career.description}
                        imageUrl={career.icon}
                        pronunciation={career.pronunciation}
                        pronunciationAudio={career.pronunciationAudio}
                        alternativeNames={career.alternativeNames}
                        videoUrl={career.introductionVideoUrl}
                      />
                    </div>
                    {career.overviewCards && career.overviewCards?.length > 0 &&
                      <div
                        className='full-width-subsection full-width-subsection--overview'
                        ref={overviewRef}
                        id='overview'
                      >
                        <CareerOverview overviewCards={career.overviewCards}/>
                      </div>
                    }
                      {career.duty &&
                        <div className='full-width-subsection' ref={dutiesRef} id='duties'>
                          <div className='sub-section'>
                            <CareerDuties
                              duty={career.duty}
                              suggestedAssets={career.careerSuggestedAssets}
                            />
                          </div>
                        </div>
                      }

                    <div className='full-width-subsection full-width-subsection--project-highlight'>
                      <ProjectHighlight projectHighlight={career.projectHighlight}/>
                    </div>

                      {Object.keys(career.pathwayConfig).length > 1 &&
                        <div ref={educationPathwayRef} className='full-width-subsection' id='education-pathway'>
                          <div className='sub-section'>
                            <EducationPathway pathwayConfig={career.pathwayConfig} />
                          </div>
                        </div>
                      }

                    <div className='full-width-subsection full-width-subsection--project-highlight-pink'>
                      <ProjectHighlight careerQuote={career.quote}/>
                    </div>

                      {(showSalarySection && showSalarySection > 0) ? (
                          <div className='full-width-subsection' ref={salaryRef} id='salary'>
                              <div className='sub-section'>
                                  <CareerSalary
                                      entryLevelSalary={career.entryLevelSalary}
                                      midLevelSalary={career.midLevelSalary}
                                      seniorLevelSalary={career.seniorLevelSalary}
                                  />
                              </div>
                          </div>
                      ) : null}
                      {career.outlook && (
                          <div className='full-width-subsection' ref={outlookRef} id='outlook'>
                              <div className='sub-section'>
                                  <CareerOutlook outlook={career.outlook} careerTitle={career.title} />
                              </div>
                          </div>
                      )}
                      {career.relatedCareerSections && career.relatedCareerSections.length > 0 && (
                          <div className='full-width-subsection' ref={relatedCareers} id={'related-careers'}>
                              <div className='sub-section'>
                                  <RelatedCareers relatedCareerSections={career.relatedCareerSections}/>
                              </div>
                          </div>
                      )}
                      <div className='full-width-subsection' ref={sourcesRef} id={'sources'}>
                          <div className='sub-section'>
                              <SourcesSection references={career.references} createdAt={career.createdAt} updatedAt={career.updatedAt} />
                          </div>
                      </div>
                  </div>
                </>
            }
        </div>
    );
};

export default CareerDetailsPage;
