import React from 'react';
import { WrappedMessage } from '../../../../../utils';
import messages from '../../../../displayMessages';
import { sanitizeConfigOptions, sanitizeUnsafeHTML } from '@labxchange/util-sanitization';
import { Icon } from '../../../../../elements';

interface CardItemProps {
    count: number;
    description: string;
    key: string;
    onClick: () => void;
}

const SkillCardComponent: React.FC<CardItemProps> = ({count, description, key, onClick}) => {
    const themeNumber = (count - 1) % 3 + 1;
    return (
        <div
            className={`skill-card-container skill-card-container__theme-${themeNumber}`}
            key={key}
            onClick={onClick}
        >
            <div className={`skill-count skill-count__theme-${themeNumber}`}>
                <div className={`skill-count-text skill-count-text__theme-${themeNumber}`} data-testid='wrapped-message'>
                    <WrappedMessage  message={messages.skillCountLabel} values={{count}} />
                </div>
            </div>
            <div className='skill-description'>
                <span
                    dangerouslySetInnerHTML={{
                        __html: sanitizeUnsafeHTML(description, sanitizeConfigOptions.UnsafeHTMLSimple)
                    }}
                />
            </div>
            <div className='learn-more-button'>
                <WrappedMessage  message={messages.skillLearnMore} />
                <Icon className='learn-more-button-icon' name='arrow-forward' fill={'#FFFFFF'} zoom='1.25em' />
            </div>
        </div>
    );
};

export default SkillCardComponent;
